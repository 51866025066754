import Const from './const'

function getKey(key) {
	return Const.DATA.KEY_PREFIX + key;
}

/**
 * 存取 localStorage
 */
export function setStore(name, content) {
    if (!name) return;
	name = getKey(name);
	return window.localStorage.setItem(name, JSON.stringify(content));
};
export function getStore(name) {
    if (!name) return;
	name = getKey(name);
	return JSON.parse(window.localStorage.getItem(name));
};


/**
 * 存取 sessionStorage
 */
export function getSession(name) {
    if (!name) return;
	name = getKey(name);
	return JSON.parse(window.sessionStorage.getItem(name));
};
export function setSession(name, content) {
    if (!name) return;
	name = getKey(name);
	return window.sessionStorage.setItem(name, JSON.stringify(content));
};


export function clearAllData() {
	window.sessionStorage.clear()
	window.localStorage.clear()
}


export function setToken(val) {
	let key = Const.DATA.KEY_TOKEN;
	return setStore(key, val);
}
export function getToken() {
	let key = Const.DATA.KEY_TOKEN;
	return getStore(key);
}

export function setUser(val) {
	let key = Const.DATA.KEY_USER;
	return setStore(key, val);
}
export function getUser() {
	let key = Const.DATA.KEY_USER;
	return getStore(key);
}

export function setUserType(val) {
	let key = Const.DATA.KEY_USER_TYPE;
	return setStore(key, val);
}
export function getUserType() {
	let key = Const.DATA.KEY_USER_TYPE;
	return getStore(key);
}

export function setUserAuth(val) {
	let key = Const.DATA.KEY_USER_AUTH;
	return setStore(key, val);
}
export function getUserAuth() {
	let key = Const.DATA.KEY_USER_AUTH;
	return getStore(key);
}

export function setProvider(val) {
	let key = Const.DATA.KEY_PROVIDER_NAME;
	return setStore(key, val);
}
export function getProvider() {
	let key = Const.DATA.KEY_PROVIDER_NAME;
	return getStore(key);
}

export function setBreadcrumbNav(val) {
	let key = Const.DATA.KEY_BREADCRUMB_NAV;
	return setSession(key, val);
}
export function getBreadcrumbNav() {
	let key = Const.DATA.KEY_BREADCRUMB_NAV;
	return getSession(key);
}

export function setSchoolAuthority(val) {
	let key = Const.DATA.KEY_SCHOOL_AUTHORITY;
	return setStore(key, val);
}
export function getSchoolAuthority() {
	let key = Const.DATA.KEY_SCHOOL_AUTHORITY;
	return getStore(key);
}

export function setOrgCurrSchool(val) {
	let key = Const.DATA.KEY_ORG_CURR_SCHOOL;
	return setStore(key, val);
}
export function getOrgCurrSchool() {
	let key = Const.DATA.KEY_ORG_CURR_SCHOOL;
	return getStore(key);
}

export function setBelong(val) {
	let key = Const.DATA.KEY_USER_BELONG;
	return setStore(key, val);
}
export function getBelong() {
	let key = Const.DATA.KEY_USER_BELONG;
	return getStore(key);
}

export function setLogoUrl(val) {
    let key = Const.DATA.KEY_LOGO_URL
    return setStore(key,val)
}
export function getLogoUrl() {
	let key = Const.DATA.KEY_LOGO_URL;
	return getStore(key);
}

export function setSystemIndex(val) {
    let key = Const.DATA.KEY_SYSTEM_INDEX
    return setSession(key,val)
}
export function getSystemIndex() {
	let key = Const.DATA.KEY_SYSTEM_INDEX;
	return getSession(key);
}