import ajax from './request/http' // 导入http中创建的axios实例
import qs from 'qs' // 根据需求是否导入qs模块
import * as Data from '../data'
import Const from '../const'

class ApiBase {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;

		this.upload = upload;
    }

    static transformObjectToUrlencodedData(obj) {
        let p = [];
        for (let key in obj) {
            p.push(
                encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
            );
        }
        return p.join('&');
    }

    post(config, moduleName, ...args) {
        const userType = Data.getUserType() || '';
        const USER_TYPE = Const.USER_TYPE
        const ADMIN = 'admin/1/';
        const SCHOOL = 'school/1/';
        const ORG = 'org/1/';
        const PROVIDER = 'provider/1/';
        const commonModuleList = ['Common', 'Dictionary', 'Print', 'UserLogin', 'UploadFile', 'AdminLogin'];
        let API_MARK = '';

        if (!commonModuleList.includes(moduleName)) {
            if (userType === USER_TYPE.SCHOOL || userType === USER_TYPE.ORG_SCHOOL) {
                API_MARK = SCHOOL;
            }
            else if (userType === USER_TYPE.ORG) {
                API_MARK = ORG;
            }
            else if (userType === USER_TYPE.ADMIN) {
                API_MARK = ADMIN;
            }
            else if (userType === USER_TYPE.PROVIDER) {
                    API_MARK = PROVIDER;
                //     if (this.baseUrl === 'https://api.record.school.rebuildsoft.com') {
                // } else {
                //     API_MARK = ADMIN;
                // }
            }
        } else if (moduleName === 'UserLogin') {
            API_MARK = 'common/1/'
        } else if (moduleName === 'AdminLogin') {
            API_MARK = 'admin/1/'
        }
        if (moduleName === 'client') {
			API_MARK = 'client/1/'
		}
        let requestFormData = Object.create(null);
        requestFormData.token = Data.getToken() || ''
        for (let i = 1; i < config.length; i++) {
            const param = args[i - 1] == null ? '' : args[i - 1];
            requestFormData[config[i]] = param;
        }
        const fullUrl = `${this.baseUrl}/${API_MARK}${config[0]}`;
        console.log(
            `%c ${config[0]} => ${fullUrl}?${ApiBase.transformObjectToUrlencodedData(requestFormData)}`,
            'color:#fff;background:#74be5f;'
        );
        return ajax.post(fullUrl, qs.stringify(requestFormData));
    }
}

class Api extends ApiBase {
    constructor(baseUrl, apiList) {
        super(baseUrl);
        for (const moduleName in apiList) {
            this[moduleName] = Object.create(null);
            const module = apiList[moduleName];
            for (const functionName in module) {
                const config = module[functionName];
                this[moduleName][functionName] = ((config) => {
                    return (...args) => {
                        return super.post(config, moduleName, ...args);
                    };
                })(config);
            }
        }
    }
}

export default Api

/**
 * @param option object{}
 * @returns {*}
 */
function upload(option) {
	if (typeof XMLHttpRequest === 'undefined') {
		return;
	}

	const xhr = new XMLHttpRequest();
	const action = option.action;

	if (xhr.upload) {
		xhr.upload.onprogress = function progress(e) {
			if (e.total > 0) {
				e.percent = e.loaded / e.total * 100;
			}
			if (typeof option === 'object' && typeof option.onProgress === 'function') {
				option.onProgress(e);
			}

		};
	}

	const formData = new FormData();

	if (option.data) {
		Object.keys(option.data).forEach(key => {
			formData.append(key, option.data[key]);
		});
	}

	formData.append(option.name, option.file, option.filename);

	xhr.onerror = function error(e) {
		if (typeof option === 'object' && typeof option.onError === 'function') {
			option.onError(e);
		}
	};

	xhr.onload = function onload() {
		if (xhr.status < 200 || xhr.status >= 300) {
			if (typeof option === 'object' && typeof option.onError === 'function') {
				return option.onError(getError(action, option, xhr));
			}
		}

		if (typeof option === 'object' && typeof option.onSuccess === 'function') {
			option.onSuccess(getBody(xhr));
		}

	};

	xhr.open('post', action, true);

	if (option.withCredentials && 'withCredentials' in xhr) {
		xhr.withCredentials = true;
	}

	const headers = option.headers || {};

	for (let item in headers) {
		if (headers.hasOwnProperty(item) && headers[item] !== null) {
			xhr.setRequestHeader(item, headers[item]);
		}
	}
	xhr.send(formData);
	return xhr;
}
function getError(action, option, xhr) {
	let msg;
	if (xhr.response) {
		msg = `${xhr.response.error || xhr.response}`;
	} else if (xhr.responseText) {
		msg = `${xhr.responseText}`;
	} else {
		msg = `fail to post ${action} ${xhr.status}`;
	}

	const err = new Error(msg);
	err.status = xhr.status;
	err.method = 'post';
	err.url = action;
	return err;
}

function getBody(xhr) {
	const text = xhr.responseText || xhr.response;
	if (!text) {
		return text;
	}

	try {
		return JSON.parse(text);
	} catch (e) {
		return text;
	}
}
