import Const from './const'
import * as Data from './data'

export const fileTypeFilter = (name) => {
    const type = name.split('.').pop();
    switch (type) {
        case 'mp3':
        case 'MP3':
        case 'm4a':
        case 'flac':
            return Const.UPLOAD_FILE_SUFFIX.AUDIO;
        case 'mp4':
        case 'MP4':
            return Const.UPLOAD_FILE_SUFFIX.VIDEO;
        case 'ppt':
        case 'PPT':
        case 'pptx':
        case 'PPTX':
            return Const.UPLOAD_FILE_SUFFIX.PPT;
        case 'zip':
        case 'ZIP':
        case 'rar':
        case 'RAR':
            return Const.UPLOAD_FILE_SUFFIX.PACKAGE;
        case 'doc':
        case 'DOC':
        case 'docx':
        case 'DOCX':
            return Const.UPLOAD_FILE_SUFFIX.DOCUMENT;
        case 'jpg':
        case 'JPG':
        case 'png':
        case 'PNG':
        case 'jpeg':
        case 'JPEG':
            return Const.UPLOAD_FILE_SUFFIX.IMAGE;
        case 'xlsx':
        case 'XLSX':
        case 'xls':
        case 'XLS':
            return Const.UPLOAD_FILE_SUFFIX.EXCLE;
        case 'wps':
        case 'WPS':
        default:
            return Const.UPLOAD_FILE_SUFFIX.OTHER;
    }
}
export const deepCopy = (target) => {
    let copyed_objs = [];//此数组解决了循环引用和相同引用的问题，它存放已经递归到的目标对象
    function _deepCopy(target){
        if((typeof target !== 'object')||!target){return target;}
        for(let i= 0 ;i<copyed_objs.length;i++){
            if(copyed_objs[i].target === target){
                return copyed_objs[i].copyTarget;
            }
        }
        let obj = {};
        if(Array.isArray(target)){
            obj = [];//处理target是数组的情况
        }
        copyed_objs.push({target:target,copyTarget:obj})
        Object.keys(target).forEach(key=>{
            if(obj[key]){ return;}
            obj[key] = _deepCopy(target[key]);
        });
        return obj;
    }
    return _deepCopy(target);
}
export const timeWithWeek = (time) => {
    let cerateTime = new Date(time * 1000);
    let month =  (cerateTime.getMonth()+1).toString().padStart(2, '0') || '',
        data = cerateTime.getDate().toString().padStart(2, '0'),
        week = Const.SERIAL_NUMBER_MAP[cerateTime.getDay()],
        hour = cerateTime.getHours().toString().padStart(2, '0'),
        min = cerateTime.getMinutes().toString().padStart(2, '0'),
        sec = cerateTime.getSeconds().toString().padStart(2, '0');
    if (month === '') {
        return ''
    } else {
        return `${month}月${data}日 周${week} ${hour}:${min}:${sec}`
    }
}
export const auth = (...authKeys) => {
    if (authKeys[0] === '') {
        return true
    }
    const roles = Data.getUserAuth() || [];
    const rolesKeys = roles.map(i => i.key)
    return rolesKeys.some((role) => {
        return authKeys.includes(role);
    });
}

/**
 *
 * @param that
 * @param getListFun  接口调用方法
 * @param paramList  除了page的参数列表
 * @param listName  接收的list名称
 * @param resListName  接口的list名称
 * @returns {Promise<unknown>}
 */
export const getListAll = (that, getListFun, paramList = [], listName = 'list', resListName = 'list') => {
	return new Promise(async (resolve, reject) => {
		let transactionId = new Date().getTime();
		let renderTransactionId = [listName + 'TransactionId']
		that[renderTransactionId] = transactionId;
		that.listName = [];
		let list = [];
		let page = 1;
		let num = 0
		while (true) {
			let res = await getListFun(page++, ...paramList)
			if (transactionId !== that[renderTransactionId]) {
				return;
			}
			list = [...list, ...res[resListName]];
			that.listName = list;
			if (res[resListName].length === 0) {
				break;
			}
			if (num++ > 1000) {
				resolve(list)
				return
			}
		}
		resolve(list)
	})
}

