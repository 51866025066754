import LogoUrl from '../assets/images/Logo.png'
export default {
    user: '',
    userType: 102,
    systemIndex: 1,
    scheduleData: [
        {
            name: '区域活动',
            beginTime: '8:00',
            endTime: '9:00',
            id: 1,
            week: [
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
            ]
        },
        {
            name: '韵律时光',
            beginTime: '9:10',
            endTime: '9:30',
            id: 2,
            week: [
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
            ]
        },
        {
            name: '主题活动',
            beginTime: '9:50',
            endTime: '10:10',
            id: 3,
            week: [
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
            ]
        },
        {
            name: '运动时光',
            beginTime: '10:10',
            endTime: '11:00',
            id: 4,
            week: [
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
            ]
        },
        {
            name: '睡前活动',
            beginTime: '11:40',
            endTime: '12:00',
            id: 5,
            week: [
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
            ]
        },
        {
            name: '区域活动',
            beginTime: '15:00',
            endTime: '15:30',
            id: 7,
            week: [
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
            ]
        },
        {
            name: '萌学时光',
            beginTime: '15:30',
            endTime: '15:50',
            id: 6,
            week: [
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
                { key: '' },
            ]
        },
    ],
    resourceClipboard: '',
    // logoUrl: LogoUrl
};
