import dayjs from 'dayjs';
import Const from '../const';
import * as Data from '../data.js'

const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * 格式化时间戳（秒|毫秒）
 * @param {timestamp} value
 */
export const timeFilter = (value, type = 1) => {
    if (value == null) {
        return ''
    }
    if (value.toString() === '0') {
        return '-';
    }
    switch(type) {
        case 1: return dayjs.unix(Number(value)).format('YYYY-MM-DD HH:mm:ss');
        case 6: return dayjs.unix(Number(value)).format('YYYY-MM-DD HH:mm');
        case 5: return dayjs.unix(Number(value)).format('YYYY-MM-DD');
        case 2: return dayjs.unix(Number(value)).format('YYYY年MM月DD日');
        case 3: return dayjs.unix(Number(value)/1000).format('YYYY年MM月DD日');
        case 4: return dayjs.unix(Number(value)/1000).tz('Asia/Shanghai').format('YYYY年MM月DD日'); // 打印用
        default: return '-';
    }
};

export const relationFilter = (val) => {
    let TYPE = Const.PARENT_TYPE
	switch (val) {
		case TYPE.FATHER: return '爸爸';
		case TYPE.MOTHER: return '妈妈';
		case TYPE.GRANDFATHER: return '爷爷';
		case TYPE.GRANDMOTHER: return '奶奶';
		case TYPE.GRANDPA: return '外公';
		case TYPE.GRANDMA: return '外婆';
		case TYPE.TEACHER: return '老师';
		default: return '';
	}
};
export const serviceTypeFilter = (val) => {
    let TYPE = Const.SERVICE_TYPE
	switch (val) {
		case TYPE.APP: return '仅小程序';
		case TYPE.PC: return '仅后台';
		default: return '全部服务';
	}
};
const timesFormat = (times) => {
    let str = dayjs.unix(times[0]).format('HH:mm') + ' - ' + dayjs.unix(times[1]).format('HH:mm');
    return str;
};

const genderFilter = (value) => { // 性别
    value = Number(value)
    let gender = '';
    switch (value) {
        case 0:
            gender = '女';
            break;
        case 1:
            gender = '男';
            break;
        default:
            gender = '-'
            break;
    }
    return gender
};
/**
 * @description:数值转换 将后端传来的数值转换成对应值
 * @param {Number} val 被除数 需要转换的数值
 * @param {Number} divisor 除数 默认为100
 * @param {Number} dp 保留几位小数 默认为2
 * @param {Number} type 乘法/除法 false=除法 true=乘法
 */
const numFixed = (val = 0 ,divisor = 100, dp = 2, type= false) => {
    if (val == 0) { return 0 }
    if (type) {
        return (val * divisor).toFixed(dp)
    } else {
        return (val / divisor).toFixed(dp)
    }
}


const timeStamp = (StatusMinute) => {
    var day = parseInt(StatusMinute / 60 / 24);
    var hour = parseInt((StatusMinute / 60) % 24);
    var min = parseInt(StatusMinute % 60);
    StatusMinute = '';
    if (day > 0) {
        StatusMinute = day + '天';
    }
    if (hour > 0) {
        StatusMinute += hour + '小时';
    }
    if (min > 0) {
        StatusMinute += parseFloat(min) + '分钟';
    }
    return StatusMinute || '-';
};

function fileSizeFormat(size) {
    if (size < 1024) {
        return size + ' B';
    } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(2) + ' KB';
    } else if (size < 1024 * 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB';
    } else if (size < 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
    }
}

function templateTypeFilter(val) {
    return Const.RESOURCE.TEMPLATE_TYPE_MAP[val] || '';
}
function resourceTypeFilter(val) {
    return Const.RESOURCE.TYPE_MAP[val] || '';
}

function labelsFilter(val) {
    return val.split(',');
}

function serialNumberFilter(num) {
    const w = parseInt(num / 10)
    const q = num % 10
    if (w > 0) {
        return `${w === 1 ? '' : Const.SERIAL_NUMBER_MAP[w]}${Const.NUMBER_UNIT_ZH_CN.SHI}${Const.SERIAL_NUMBER_MAP[q]}`
    } else {
        return Const.SERIAL_NUMBER_MAP[q]
    }
}

function monthFilter(num) { // 数字月份转中文
    return Const.SERIAL_NUMBER_MAP[num]
}

function inviteStatusFilter(state) { // 测试邀请的状态
    const INVITE_STATE_MAP = {
        0: '● 已创建',
        100: '● 测试中',
        200: '● 测试完成',
    }
    return INVITE_STATE_MAP[state] || '-'
}

function authFilter(...authKeys) {
    if (authKeys[0] === '') {
        return true
    }
    const roles = Data.getUserAuth() || [];
    const rolesKeys = roles.map(i => i.key)
    const hasAuth = rolesKeys.some((role) => {
        return authKeys.includes(role);
    });
    return hasAuth
}
function orderStatusFilter(status, to='word') {
    const STATUS = Const.ORDER.STATUS
	switch (status) {
		case STATUS.INIT:            return "待支付"
		case STATUS.ORDER_PAY:       return "支付完成"
		case STATUS.CANCEL:          return "已取消"
		case STATUS.WAITING_REFUND:  return "等待退款"
		case STATUS.COMPLETE_REFUND: return "退款完成"
		case STATUS.ORDER_CLOSE:     return "订单完成"
		case STATUS.REFUND_FAIL:     return "退款失败"
		case STATUS.ORDER_LAPSE:     return "订单失效"
		default: return "-"
	}
}

function payStatusColorFilter(status) {
    const STATUS = Const.ORDER.STATUS
	switch (status) {
		case STATUS.INIT:            return 'blue'  // 待支付
		case STATUS.ORDER_PAY:       return 'green' // 支付完成
		case STATUS.CANCEL:          return 'grey'  // 已取消
		case STATUS.WAITING_REFUND:  return 'red'   // 等待退款
		case STATUS.COMPLETE_REFUND: return 'grey'  // 退款完成
		case STATUS.ORDER_CLOSE:     return 'green' // 订单完成
		case STATUS.REFUND_FAIL:     return 'red'   // 退款失败
		default: return "grey"
	}
}

function orderTypeFilter(type) {
	const TYPE = Const.ORDER.TYPE
	switch (type) {
        case TYPE.WX:         return '微信'
        case TYPE.WX_H5:      return '微信'
        case TYPE.WX_SERVICE: return '微信'
        case TYPE.ALIPAY_H5:  return '支付宝'
        case TYPE.ALIPAY:     return '支付宝'
		default: return "无"
	}
}
function orderTypeIconFilter(type) {
	const TYPE = Const.ORDER.TYPE
	switch (type) {
        case TYPE.WX:         return 'icon-WeChat'
        case TYPE.WX_H5:      return 'icon-WeChat'
        case TYPE.WX_SERVICE: return 'icon-WeChat'
        case TYPE.ALIPAY_H5:  return 'icon-Alipay'
        case TYPE.ALIPAY:     return 'icon-Alipay'
		default: return ""
	}
}

function orderSourceFilter(source_type) {
    const SOURCE_TYPE = Const.ORDER.SOURCE_TYPE
	switch (source_type) {
		case SOURCE_TYPE.APP: return "小程序"
		case SOURCE_TYPE.H5:  return "H5"
		case SOURCE_TYPE.WEB: return "后台网页"
		default: return "-"
	}
}

function courseTypeFilter(type) {
    const TYPE = Const.COURSE.TYPE
    switch (type) {
		case TYPE.TEXT: return "图文"
		case TYPE.AUDIO: return "音频"
		case TYPE.VIDEO: return "视频"
		case TYPE.SPECIAL_COLUMN: return "专栏"
	}
}

function courseStatusFilter(status, to = 'word') {
    const STATUS = Const.COURSE.STATUS
    switch (to) {
        case 'word' :
            switch (status) {
                case STATUS.SOLDOUT: return "已下架"
                case STATUS.WAITING: return "待上架"
                case STATUS.GALLERY: return "已上架"
                default: return "-"
            }
        case 'class' :
            switch (status) {
                case STATUS.SOLDOUT: return "gray"
                case STATUS.WAITING: return "orange"
                case STATUS.GALLERY: return "green"
                default: return "gray"
            }
    }
}

function learnStatusFilter(status) {
    const STATUS = Const.COURSE.STATUS
    switch (status) {
		// case STATUS.SOLDOUT: return "已下架"
		// case STATUS.WAITING: return "待上架"
		// case STATUS.GALLERY: return "已上架"
		default: return "success"
	}
}
function convertStatusFilter(val) {
    for (const item of Const.COURSE.CONVERT_STATUS) {
        if (item.value == val) {
            return item.name;
        }
    }
    return '-';
}
function parkStatusFilter(val) {
    for (const item of Const.PARK_STATUSL) {
        if (item.value == val) {
            return item.name;
        }
    }
    return '-';
}
function discountStatusFilter(val) {
    for (const item of Const.COURSE.DISCOUNT_STATUS) {
        if (item.value == val) {
            return item.name;
        }
    }
    return '-';
}

function deviceTimeTypeFilter(type) {
    switch (type) {
        case 0:
            return '开放';
        case 1:
            return '暂未开放';
        case 2:
            return '开放时间段';
        default:
            return '暂未开放';
    }
};
export default {
    numFixed,
    timeFilter,
    authFilter,
    genderFilter,
    timeStamp,
	orderStatusFilter,
    monthFilter,
    fileSizeFormat,
    templateTypeFilter,
    labelsFilter,
    serialNumberFilter,
    resourceTypeFilter,
    inviteStatusFilter,
	orderTypeFilter,
    orderTypeIconFilter,
	payStatusColorFilter,
	orderSourceFilter,
    courseStatusFilter,
    learnStatusFilter,
    courseTypeFilter,
    convertStatusFilter,
    parkStatusFilter,
    discountStatusFilter,
    timesFormat,
    deviceTimeTypeFilter,
	relationFilter,
    serviceTypeFilter,
};
