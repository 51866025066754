import Const from './const'
import * as Data from './data'
import * as Utils from './utils'
import Api from './api/apiList'
import operationApi from './api/operationApiList'

let Core = {
    Const,
    Data,
    Api,
    operationApi,
    Utils,
}

export default Core