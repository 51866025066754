function Const() {
    return {
        DATA: {
            KEY_PREFIX: 'smartschool.data.',

            KEY_TOKEN: 'smartschool.token',
            KEY_USER_TYPE: 'smartschool.user_type',
            KEY_PROVIDER_NAME: 'smartschool.provider.name',
            KEY_BREADCRUMB_NAV: 'smartschool.breadcrumbnav',
            KEY_SCHOOL_AUTHORITY: 'smartschool.school_authority',
            KEY_ORG_CURR_SCHOOL: 'smartschool.org_curr_school',

            KEY_USER_BELONG: 'smartschool.user_belong',
            KEY_USER: 'smartschool.user',
            KEY_USER_AUTH: 'smartschool.user_auth',
            KEY_LOGO_URL: 'smartschool.logo_url',
            KEY_SYSTEM_INDEX: 'smartschool.system_index',
        },
        NET: {
            URL_POINT: 'https://api.course.school.rebuildsoft.com',
            URL_POINT_DEV: 'http://api.course.dev.school.rebuildsoft.com',
			URL_POINT_DEMO: 'http://demo-api.course.school.rebuildsoft.com',

            // 正式服
			CLIENT_URL_POINT: 'https://api.record.school.rebuildsoft.com',
            // 测试服
			CLIENT_URL_POINT_DEV: 'http://dev-api.record.school.rebuildsoft.com',

            FILE_UPLOAD_END_POINT: 'https://api.course.school.rebuildsoft.com/file/file-upload/',

			FILE_URL:         'https://smartschool.oss-cn-hangzhou.aliyuncs.com/',
            FILE_URL_PREFIX:  'https://smartschool.oss-cn-hangzhou.aliyuncs.com/file/',
            FILE_URL_PREFIX2:  'https://smartschool.oss-cn-hangzhou.aliyuncs.com/file/file/',
            IMG_URL_PREFIX:   'https://smartschool.oss-cn-hangzhou.aliyuncs.com/file/image/',
        },
        USER_TYPE: {
            PROVIDER: 102,  // 资源方账号
            ORG: 202,       // 机构账号
            ORG_SCHOOL: 203,// 作为学校登录的机构
            SCHOOL: 302,    // 学校账号
            ADMIN: 12,      // 管理方账号
        },
        USER_TYPE_MAP: {
            102: '资源方',
            202: '机构',
            203: '学校',
            302: '学校',
            12: '平台',
        },
        VIDEO_ACCEPT: '.mp4, .avi, .wmv, .mov, .flv, .rmvb, .3gp, .m4v, .mkv',
        AUDIO_ACCEPT: '.mp3, .m4a',
        UPLOAD_FILE_SUFFIX: {
            VIDEO: 'video',
            AUDIO: 'audio',
            DOCUMENT: 'document',
            PPT: 'ppt',
            PACKAGE: 'package',
            IMAGE: 'image',
            EXCLE: 'excle',
            OTHER: 'file',
        },
        RESOURCE_CONFIG: {
            ACTIVITY: [
                {
                    title: '基本信息',
                    type: 'form',
                    content: [
                        { name: '实施时间', value: '', type: 'date' },
                    ]
                },
                {
                    title: '教学目标',
                    type: 'ul',
                    content: []
                },
                {
                    title: '教学内容',
                    type: 'ul-jx',
                    content: []
                },
            ],
            GAME: [
                {
                    title: '教学目标',
                    type: 'ul',
                    content: []
                },
                {
                    title: '游戏准备',
                    type: 'ul',
                    content: []
                },
                {
                    title: '游戏玩法',
                    type: 'ul',
                    content: []
                }
            ],
            THREE: [
                {
                    title: '教学目标',
                    type: 'ul',
                    content: []
                },
                {
                    title: '教学指导',
                    type: 'ul',
                    content: []
                },
            ]
        },
        RESOURCE: {
            TEMPLATE_TYPE_MAP: {
                1: '主题活动',
                2: '规则游戏',
                3: '模版三',
            },
            TEMPLATE_TYPE_MAP_LIST: [
                { label: '主题活动', value: 1 },
                { label: '规则游戏', value: 2 },
                { label: '模版三', value: 3 },
            ],
            TYPE_MAP: {
                1: '音乐',
                2: '厨房',
                3: '运动',
                // 4: '美术',
                5: '主题活动',
                6: '美术游戏',
                7: '体育游戏',
                8: '语言游戏',
                9: '数学游戏',
                10: '思维游戏'
            },
            TYPE_MAP_LIST: [
                { label: '音乐', value: 1 },
                { label: '厨房', value: 2 },
                { label: '运动', value: 3 },
                // { label: '美术', value: 4 },
                { label: '主题活动', value: 5 },
                { label: '美术游戏', value: 6 },
                { label: '体育游戏', value: 7 },
                { label: '语言游戏', value: 8 },
                { label: '数学游戏', value: 9 },
                { label: '思维游戏', value: 10 },
            ],
            LABEL_MAP: {
                1: '认知',
                2: '节日',
                3: '社会性',
            },
            LABEL_MAP_LIST: [
                { label: '认知', value: 1 },
                { label: '节日', value: 2 },
                { label: '社会性', value: 3 },
            ]
        },
        EVALUATION_CONFIG: {
            DEFAULT: [
                {
                    title: '发展目标',
                    type: 'ul-faq',
                    content: [
                        {
                            title: '',
                            list: [
                                {
                                    name: '',
                                    options:[
                                        { icon: '', value: '' }
                                    ]
                                }
                            ]
                        }
                    ]
                },
            ]
        },
        MONTH_LIST: [
            { label: '一月', value: 1 },
            { label: '二月', value: 2 },
            { label: '三月', value: 3 },
            { label: '四月', value: 4 },
            { label: '五月', value: 5 },
            { label: '六月', value: 6 },
            { label: '七月', value: 7 },
            { label: '八月', value: 8 },
            { label: '九月', value: 9 },
            { label: '十月', value: 10 },
            { label: '十一月', value: 11 },
            { label: '十二月', value: 12 },
        ],
        SERIAL_NUMBER_MAP: {
            0: '',
            1: '一',
            2: '二',
            3: '三',
            4: '四',
            5: '五',
            6: '六',
            7: '七',
            8: '八',
            9: '九',
            10: '十',
            11: '十一',
            12: '十二',
        },
        NUMBER_UNIT_ZH_CN: {
            SHI: '十',
            BAI: '百'
        },
        PREDEFINE_COLORS: {
            WEEKPLAN_BGCOLORS: [
                {
                    color:'#63AFF5',
                    selected: false
                },
                {
                    color:'#6D8AE2',
                    selected: false
                },
                {
                    color:'#B498E6',
                    selected: false
                },
                {
                    color:'#37D2AB',
                    selected: false
                },
                {
                    color:'#FE8794',
                    selected: false
                },
                {
                    color:'#A8D53D',
                    selected: false
                },
                {
                    color:'#EC826E',
                    selected: false
                },
                {
                    color:'#FFC069',
                    selected: false
                },
                {
                    color:'#36CFC9',
                    selected: false
                },
                {
                    color:'#FF85C0',
                    selected: false
                },
            ],
            WEEKPLAN_BGCOLORS_MAP: {
                '#63AFF5' : 0,
                '#6D8AE2' : 1,
                '#B498E6' : 2,
                '#37D2AB' : 3,
                '#FE8794' : 4,
                '#A8D53D' : 5,
                '#EC826E' : 6,
                '#FFC069' : 7,
                '#36CFC9' : 8,
                '#FF85C0' : 9,
            }
        },
        EVALUATION_ICONLIST: [
            {index:'1',  src:require('@/assets/images/evaluation/aini.png'),desc:'爱你'},
            {index:'2',  src:require('@/assets/images/evaluation/bishi.png'),desc:'鄙视'},
            {index:'3',  src:require('@/assets/images/evaluation/bizui.png'),desc:'闭嘴'},
            {index:'4',  src:require('@/assets/images/evaluation/bulini.png'),desc:'不理你'},
            {index:'5',  src:require('@/assets/images/evaluation/daku.png'),desc:'大哭'},
            {index:'6',  src:require('@/assets/images/evaluation/deyi.png'),desc:'得意'},
            {index:'7',  src:require('@/assets/images/evaluation/gaxiao.png'),desc:'尬笑'},
            {index:'8',  src:require('@/assets/images/evaluation/jiaxiao.png'),desc:'假笑'},
            {index:'9',  src:require('@/assets/images/evaluation/kaixin.png'),desc:'开心'},
            {index:'10', src:require('@/assets/images/evaluation/keai.png'),desc:'可爱'},
            {index:'11', src:require('@/assets/images/evaluation/liuhan.png'),desc:'流汗'},
            {index:'12', src:require('@/assets/images/evaluation/nanguo.png'),desc:'难过'},
            {index:'13', src:require('@/assets/images/evaluation/se.png'),desc:'色'},
            {index:'14', src:require('@/assets/images/evaluation/weixiao.png'),desc:'微笑'},
            {index:'15', src:require('@/assets/images/evaluation/xiaoku.png'),desc:'笑哭'},
            {index:'16', src:require('@/assets/images/evaluation/xiudaliao.png'),desc:'羞大了'},
            {index:'17', src:require('@/assets/images/evaluation/yukuai.png'),desc:'愉快'},
            {index:'18', src:require('@/assets/images/evaluation/zhiqi.png'),desc:'稚气'},
        ],
		MONEY_TEMPLATE: [
			{
				price: 990,
				num: '',
				type: 0
			},
			{
				price: 45000,
				num: 50,
				type: 1
			},
			{
				price: 85000,
				num: 100,
				type: 2
			},
			{
				price: 170000,
				// price: 1,
				num: 200,
				type: 3
			},
		],
		PAY_TYPE: [
			{	// 微信
				name: "微信",
				type: 1
			},
			{	// 支付宝
				name: "支付宝",
				type: 2
			},
			{	// h5支付宝
				name: "支付宝",
				type: 6
			},
		],
		PAY_TYPE_NAME: {
			wx: 1,
			zhifubao: 2,
			h5zhifubao: 6
		},
        PARK_STATUSL: [
            {value: 100, name: '试用'},
            {value: -100, name: '被禁用'},
            {value: 400, name: '正常使用'},
        ],
		ORDER_PAY_TYPE_LIST: {
			CHANNEL_WX: 1,  // 微信支付
			CHANNEL_WX_H5: 2,  // 微信支付
			CHANNEL_WX_SERVICE: 3,  // 微信公众号
			CHANNEL_ALIPAY_H5: 6,  // 支付宝
			CHANNEL_ALIPAY: 7,  // 支付宝
		},
		ORDER_CHANNEL_LIST: {
			SOURCE_TYPE_APP: 100, //小程序
			SOURCE_TYPE_H5: 200, //H5
			SOURCE_TYPE_WEB: 300, //后台网页
		},
        ORDER: {
            STATUS: {
                INIT: 100,             // 初始/待支付
                ORDER_PAY: 200,        // 支付完成
                ORDER_LAPSE: -100,     // 订单失效
                CANCEL: -200,          // 已取消
                WAITING_REFUND: -300,  // 等待退款
                COMPLETE_REFUND: -400, // 退款完成
                ORDER_CLOSE: 400,      // 订单完成
                REFUND_FAIL: -500,     // 退款失败
            },
            STATUS_LIST: [
                { value: 100,  name: '待支付' },
                { value: 200,  name: '支付完成' },
                { value: -100,  name: '订单失效' },
                { value: -200, name: '已取消' },
                { value: -300, name: '等待退款' },
                { value: -400, name: '退款完成' },
                { value: 400,  name: '订单关闭' },
                { value: -500, name: '退款失败' },
            ],
            TYPE: {
                WX: 1,     // 微信小程序
                WX_H5: 2,  // 微信H5
                WX_SERVICE: 3, // 微信公众号
                ALIPAY_H5: 6,  // 支付宝H5
                ALIPAY: 7,     // 支付宝小程序
            },
            TYPE_LIST: [
                { value: 1, name: '微信小程序' },
                { value: 2, name: '微信H5' },
                { value: 3, name: '微信公众号' },
                { value: 7, name: '支付宝小程序' },
                { value: 6, name: '支付宝H5' },
            ],
            SOURCE_TYPE: {
                APP: 100,  //小程序
                H5:  200,  //H5
                WEB: 300,  //后台网页
            },
            SOURCE_TYPE_LIST: [
                { value :100, name: '小程序' },
                { value :200, name: 'H5' },
                { value :300, name: '后台网页' },
            ],
        },
        COURSE: {
            TYPE: {  // media_type
                TEXT: 10, // 图文
                AUDIO: 20, // 音频
                VIDEO: 30, // 视频
                SPECIAL_COLUMN: 40, // 专栏
            },
            STATUS: {
                SOLDOUT : 0, // 已下架
                WAITING : 1, // 待上架
                GALLERY : 2, // 已上架
            },
            STATUS_LIST: [
                { value: '0', name: '已下架' },
                { value: '1', name: '待上架' },
                { value: '2', name: '已上架' },
            ],
            CONVERT_STATUS: [
                {value: 10, name: '待开始'},
                {value: 20, name: '未兑换'},
                {value: 90, name: '过期'},
                {value: 100, name: '已兑换'},
            ],
            DISCOUNT_STATUS: [
                {value: 0, name: '初始化'},
                {value: 10, name: '未使用'},
                {value: 20, name: '已使用'},
                {value: 30, name: '逾期'},
            ],
        },

        WEEKS_LIST_DEVICE: [
            {
                week: '星期一',
                type: 1,
                timeList: [],
                value: 1
            },
            {
                week: '星期二',
                type: 1,
                timeList: [],
                value: 2
            },
            {
                week: '星期三',
                type: 1,
                timeList: [],
                value: 3
            },
            {
                week: '星期四',
                type: 1,
                timeList: [],
                value: 4
            },
            {
                week: '星期五',
                type: 1,
                timeList: [],
                value: 5
            },
            {
                week: '星期六',
                type: 1,
                timeList: [],
                value: 6
            },
            {
                week: '星期日',
                type: 1,
                timeList: [],
                value: 7
            },
            // {   // 单次生效
            //     week: '单次',
            //     type: 1,
            //     only: 1,
            //     timeList: [],
            //     date: '',
            //     value: 8
            // }
        ],
        EXPIRATION_TYPE: {
            UNSUBSCRIBED: 0, // 未订阅
            SUBSCRIBED:   1, // 订阅
            OVERDUE:      2, // 到期
        },
		PARENT_TYPE: {
            FATHER: 1,      // 爸爸
			MOTHER: 2,      // 妈妈
			GRANDFATHER: 3, // 爷爷
			GRANDMOTHER: 4, // 奶奶
			GRANDPA: 5,     // 外公
			GRANDMA: 6,     // 外婆
			TEACHER: 11,    // 老师
		},
		SERVICE_TYPE: {
            APP: 2,      // 仅小程序
			PC: 3,      // 仅后台
		},
        DISPLAY_TEMP: {
            AUTH_LIST_TEMP: [
                {list: [], select: [], key: 'school',  name: '学校管理' },
                {list: [], select: [], key: 'post',    name: '发布管理' },
                {list: [], select: [], key: 'student', name: '学员管理' },
                {list: [], select: [], key: 'class',   name: '班级管理' },
                {list: [], select: [], key: 'record',  name: '记录管理' },
                {list: [], select: [], key: 'week',    name: '周计划管理' },
                {list: [], select: [], key: 'org',     name: '机构管理' },
				{list: [], select: [], key: 'parent',  name: '家长管理' },
                // {list: [], select: [], key: 'all',     name: '设备管理' },
            ],
        },
    }
}
export default new Const()
