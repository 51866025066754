import Const from '../core/const'

import Layout from '../views/layout';
import Login from '../views/Login';
import Register from '../views/Register';

const PROVIDER   = Const.USER_TYPE.PROVIDER
const ORG        = Const.USER_TYPE.ORG
const SCHOOL     = Const.USER_TYPE.SCHOOL
const ORG_SCHOOL = Const.USER_TYPE.ORG_SCHOOL
const ADMIN      = Const.USER_TYPE.ADMIN

const routes = [
    {
        path: '/',
        redirect: '/login',
        meta: {
            hidden: true,
        }
    },
    { // login
        path: '/login',
        component: Login,
        meta: {
            title: '登录',
            classify: 1,
            hidden: true,
        }
    },
    { // register
        path: '/register',
        component: Register,
        meta:{
            title: '注册',
            classify: 1,
            hidden: true,
        }
    },
    { // public-part
        path: '/public-part',
        component: Layout,
        meta: {
            title: '公共部件',
            classify: '0',
            hidden: true,
        },
        children: [
            {
                path: '/public-part/video-player',
                component: () => import('../views/public-part/video-player'),
                hidden: true,
                meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '查看视频',
                    classify: 1,
                },
            },
            {
                path: '/public-part/video-player-ol',
                component: () => import('../views/public-part/video-player'),
                hidden: true,
                meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '查看视频',
                    classify: 3,
                },
            },
            {
                path: '/public-part/text-image',
                component: () => import('../components/online-edu/TextImage'),
                hidden: true,
                meta: {
                    roles: [PROVIDER],
                    title: '查看图文详情',
                    classify: 3,
                },
            },
        ]
    },
    {  // public-part/pdf-reader
        path: '/public-part/pdf-reader',
        component: () => import('../views/public-part/pdf-reader'),
        meta:{
            roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
            title: 'PDF查看',
            classify: 1,
            hidden: true,
        }
    },

    {  // plan
        path: '/plan',
        component: Layout,
        meta:{
            roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
            title: '教学计划',
            classify: 1,
            icon: 'icon-teaching-programme',
            auth: ['PROVIDER', 'SCHOOL', 'ORG_SCHOOL'],
        },
        children: [
            {
                path: '/plan/school-plan-list',
                component: () => import('../views/plan/plan-list'),
                meta:{
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '学校方',
                    classify: 1,
                    auth: [''],
                },
            },
            {
                path: '/plan/provider-plan-list',
                component: () => import('../views/plan/plan-list'),
                meta:{
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '资源方',
                    classify: 1,
                    auth: [''],
                },
            },
            {
                path: '/plan/plan-detail',
                component: () => import('../views/plan/plan-detail'),
                meta:{
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '计划详情',
                    classify: 1,
                    auth: [''],
                    hidden: true,
                },
            },
            {
                path: '/plan/schedule-edit',
                component: () => import('../views/plan/schedule-edit'),
                meta:{
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '周计划',
                    classify: 1,
                    auth: [''],
                    hidden: true,
                },
            },
            {
                path: '/plan/evaluation-edit',
                component: () => import('../views/plan/evaluation-edit'),
                meta: {
                    roles: [PROVIDER, SCHOOL, ORG_SCHOOL],
                    title: '月评模板',
                    classify: 1,
                    auth: [''],
                    hidden: true,
                },
            },
            {
                path: '/plan/evaluation-view',
                component: () => import('../views/plan/evaluation-view'),
                meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '月评模板',
                    classify: 1,
                    auth: [''],
                    hidden: true,
                },
            },
        ]
    },
    { // 周计划打印 -- 必须单独放在外面
        path: '/plan/schedule-print',
        component: () => import('../views/plan/schedule-print-new'),
        meta:{
            title: '周计划打印',
            classify: 1,
            auth: [''],
            hidden: true,
        },
        children: [
            {
                path: '/schedule/print',
                redirect: '/plan/schedule-print',
            }
        ]
    },

    {  // resources
        path: '/resources',
        component: Layout,
        redirect: '/resources/resources-list',
        meta:{
            roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
            title: '资源库',
            classify: 1,
            icon: 'icon-resource-library',
            auth: [''],
        },
        children: [
            {
                path: '/resources/resources-list',
                component: () => import('../views/resources/resources-list'),
                meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '资源列表',
                    classify: 1,
                    auth: [''],
                },
            },
            {
                path: '/resources/resources-edit',
                component: () => import('../views/resources/resources-edit'),
                meta: {
                    roles: [PROVIDER],
                    title: '资源详情',
                    classify: 1,
                    auth: [''],
                    hidden: true,
                },
            },
            {
                path: '/resources/resources-view',
                component: () => import('../views/resources/resources-view'),
                meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '资源详情',
                    classify: 1,
                    auth: [''],
                    hidden: true,
                },
            },
        ]
    },

    {  // org
        path: '/org',
        component: Layout,
        redirect: '/org/org-list',
        meta:{
            title: '机构管理',
            classify: 1,
            roles: [ADMIN, PROVIDER],
            icon: 'icon-org-management',
            auth: [''],
        },
        children: [
            {
                path: '/org/org-list',
                component: () => import('../views/org/org-list'),
                meta: {
                    roles: [ADMIN, PROVIDER],
                    title: '机构管理',
                    classify: 1,
                    auth: [''],
                },
            },
            {
                path: '/org/org-detail',
                component: () => import('../views/org/org-detail'),
                meta: {
                    roles: [ADMIN, PROVIDER],
                    title: '机构详情',
                    classify: 1,
                    auth: [''],
                    hidden: true,
                },
            },
        ]
    },
    {  // school
        path: '/school',
        component: Layout,
        redirect: '/school/school-list',
        meta:{
            roles: [ADMIN, PROVIDER, ORG],
            title: '学校管理',
            classify: 1,
            icon: 'icon-park',
            auth: [''],
        },
        children: [
            {
                path: '/school/school-list',
                component: () => import('../views/school/school-list'),
                meta: {
                    roles: [ADMIN, PROVIDER, ORG],
                    title: '学校管理',
                    classify: 1,
                    auth: [''],
                },
            },
            {
                path: '/school/school-detail',
                component: () => import('../views/school/school-detail'),
                meta: {
                    roles: [ADMIN, PROVIDER, ORG],
                    title: '学校详情',
                    classify: 1,
                    auth: [''],
                    hidden: true,
                },
            },
        ]
    },
    {  // class
        path: '/class',
        component: Layout,
        redirect: '/class/class-list',
        meta:{
            roles: [ADMIN, PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
            title: '班级管理',
            classify: 1,
            icon: 'icon-school-management',
            auth: ['ADMIN', 'SCHOOL', 'ORG_SCHOOL'],
        },
        children: [
            {
                path: '/class/class-list',
                component: () => import('../views/class/class-list'),
                meta: {
                    roles: [ADMIN, SCHOOL, ORG_SCHOOL],
                    title: '班级管理',
                    classify: 1,
                    auth: [''],
                },
            },
            {
                path: '/class/class-detail',
                component: () => import('../views/class/class-detail'),
                meta: {
                    roles: [ADMIN, PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '班级详情',
                    classify: 1,
                    auth: [''],
                    hidden: true,
                },
            },
        ]
    },
    {  // device
        path: '/device',
        component: Layout,
        redirect: '/device/device-list',
        meta:{
            roles: [SCHOOL, ORG_SCHOOL],
            title: '设备管理',
            classify: 1,
            icon: 'icon-device',
            auth: [''],
        },
        children: [
            {
                path: '/device/device-list',
                component: () => import('../views/device/device-list'),
                meta: {
                    roles: [ADMIN, PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '设备管理',
                    classify: 1,
                    auth: [''],
                },
            },
            {
                path: '/device/device-detail',
                component: () => import('../views/device/device-detail'),
                meta: {
                    roles: [ADMIN, PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
                    title: '设备详情',
                    classify: 1,
                    auth: [''],
                    hidden: true,
                },
            },
        ]
    },

    {  // 平台管理 订单
        path: '/platform-order',
        component: Layout,
        redirect: '/platform-order/order-list',
        meta: {
            roles: [ADMIN],
            title: '订单管理',
            classify: 1,
            icon: 'icon-org-order',
            auth: [''],
        },
        children: [
            {
                path: '/platform-order/order-list',
                component: () => import('../views/platform-order/order-list'),
                meta: {
                    roles: [ADMIN],
                    title: '订单管理',
                    classify: 1,
                    auth: [''],
                },
            },
            {
                path: '/platform-order/download-list',
                component: () => import('../views/platform-order/download-list'),
                meta: {
                    roles: [ADMIN],
                    title: '照片订单管理',
                    classify: 1,
                    auth: [''],
                    // hidden: true,
                },
            },
        ]
    },

    {  // account
        path: '/account',
        component: Layout,
        redirect: '/account/account-list',
        meta:{
            title: '账号管理',
            classify: 1,
            icon: 'icon-account-management',
            auth: [''],
        },
        children: [
            {
                path: '/account/account-list',
                component: () => import('../views/account/account-list'),
                meta: {
                    title: '账号管理',
                    classify: 1,
                    auth: [''],
                },
            },
        ]
    },
	{  // authority
		path: '/authority',
		component: Layout,
        redirect: '/authority/authority-list',
		meta:{
			title: '权限管理',
			classify: 1,
            icon: 'icon-authority-management',
            auth: ['MANAGER'],
		},
		children: [
			{
				path: '/authority/authority-list',
				component: () => import('../views/authority/authority-list'),
				meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
					title: '权限管理',
					classify: 1,
                    auth: ['MANAGER'],
				},
			},
		]
	},

    {  // 运营管理 发育商测评
        path: '/operate-quotient',
        component: Layout,
        redirect: '/operate-quotient/invite-list',
        meta: {
            roles: [ORG, SCHOOL, ORG_SCHOOL],
            title: '发育商测评',
            classify: 2,
            icon: 'icon-development-quotient',
            auth: [''],
        },
        children: [
            {
                path: '/operate-quotient/invite-list',
                component: () => import('../views/operate-quotient/invite-list'),
                meta: {
                    roles: [ORG, SCHOOL, ORG_SCHOOL],
                    title: '邀请列表',
                    classify: 2,
                    auth: [''],
                },
            },
			{
				path: '/operate-quotient/quota-list',
				component: () => import('../views/operate-quotient/quota-list'),
				meta: {
                    roles: [ORG, SCHOOL, ORG_SCHOOL],
					title: '名额管理',
					classify: 2,
                    auth: [''],
				},
			},
            {
                path: '/operate-quotient/recoed-list',
                component: () => import('../views/operate-quotient/record-list'),
                meta: {
                    roles: [ORG, SCHOOL, ORG_SCHOOL],
                    title: '测评报告',
                    classify: 2,
                    auth: [''],
                },
            },
            {
                path: '/operate-quotient/record-detail',
                component: () => import('../views/operate-quotient/record-detail'),
                meta: {
                    roles: [ORG, SCHOOL, ORG_SCHOOL],
                    title: '报告详情',
                    classify: 2,
                    auth: [''],
                    hidden: true,
                },
            },

            {
                path: '/operate-quotient/event-list',
                component: () => import('../views/operate-quotient/event-list'),
                meta: {
                    roles: [ORG, SCHOOL, ORG_SCHOOL],
                    title: '测评活动',
                    classify: 2,
                    auth: [''],
                },
            },
            {
                path: '/operate-quotient/event-edit',
                component: () => import('../views/operate-quotient/event-edit'),
                meta: {
                    roles: [ORG, SCHOOL, ORG_SCHOOL],
                    title: '活动设置',
                    classify: 2,
                    auth: [''],
                    hidden: true,
                },
            },
            {
                path: '/operate-quotient/event-detail',
                component: () => import('../views/operate-quotient/event-detail'),
                meta: {
                    roles: [ORG, SCHOOL, ORG_SCHOOL],
                    title: '活动详情',
                    classify: 2,
                    auth: [''],
                    hidden: true,
                },
            },

            {
                path: '/operate-quotient/poster-create',
                component: () => import('../views/operate-quotient/poster-edit'),
                meta: {
                    roles: [ORG, SCHOOL, ORG_SCHOOL],
                    title: '海报生成',
                    classify: 2,
                    auth: [''],
                    hidden: true,
                },
            },
        ]
    },
    {  // 运营管理 设置
        path: '/operate-set',
        component: Layout,
        redirect: '/operate-set/channel-list',
        meta: {
            roles: [ORG, SCHOOL, ORG_SCHOOL],
            title: '运营设置',
            classify: 2,
            icon: 'icon-set',
            auth: [''],
        },
        children: [
            {
                path: '/operate-set/channel-list',
                component: () => import('../views/operate-set/channel-list'),
                meta: {
                    roles: [ORG, SCHOOL, ORG_SCHOOL],
                    title: '渠道管理',
                    classify: 2,
                    auth: [''],
                },
            },
        ]
    },

    {  // 在线教育 课程管理
		path: '/online-course',
		component: Layout,
        redirect: '/online-course/course-list/image-text',
		meta:{
            roles: [PROVIDER],
			title: '课程管理',
			classify: 3,
            icon: 'icon-course',
            auth: [''],
		},
		children: [
            {
                path: '/online-course/course-list',
                redirect: '/online-course/course-list/image-text',
				meta: {
                    hidden: true,
                }
            },
			{
				path: '/online-course/course-list/image-text',
				component: () => import('../views/online-course/course-list'),
				meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
					title: '图文',
					classify: 3,
                    type: Const.COURSE.TYPE.TEXT,
                    auth: [''],
				},
			},
            {
				path: '/online-course/course-list/voice',
				component: () => import('../views/online-course/course-list'),
				meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
					title: '音频',
					classify: 3,
                    type: Const.COURSE.TYPE.AUDIO,
                    auth: [''],
				},
			},
            {
				path: '/online-course/course-list/video',
				component: () => import('../views/online-course/course-list'),
				meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
					title: '视频',
					classify: 3,
                    type: Const.COURSE.TYPE.VIDEO,
                    auth: [''],
				},
			},
            {
				path: '/online-course/course-list/special-column',
				component: () => import('../views/online-course/course-list'),
				meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
					title: '专栏',
					classify: 3,
                    type: Const.COURSE.TYPE.SPECIAL_COLUMN,
                    auth: [''],
				},
			},
            {
				path: '/online-course/course-detail',
				component: () => import('../views/online-course/course-detail'),
				meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
					title: '课程详情',
					classify: 3,
                    auth: [''],
                    hidden: true,
                }
            },
            {
				path: '/online-course/course-edit',
				component: () => import('../views/online-course/course-edit'),
				meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
					title: '编辑课程',
					classify: 3,
                    auth: [''],
                    hidden: true,
				},
			},
		]
	},
    {  // 在线教育 订单管理
        path: '/online-order',
		component: Layout,
        redirect: '/online-order/order-list',
		meta:{
            roles: [PROVIDER],
			title: '订单管理',
			classify: 3,
            icon: 'icon-order',
            auth: [''],
		},
		children: [
			{
				path: '/online-order/order-list',
				component: () => import('../views/online-order/order-list'),
				meta: {
                    roles: [PROVIDER, ORG, SCHOOL, ORG_SCHOOL],
					title: '订单管理',
					classify: 3,
                    auth: [''],
				},
			},
		]
    },
    {  // 在线教育 营销管理
        path: '/online-marketing',
		component: Layout,
        redirect: '/online-marketing/convert-list',
		meta:{
            roles: [PROVIDER],
			title: '营销管理',
			classify: 3,
            icon: 'icon-marketing-management',
            auth: [''],
		},
		children: [
			{
				path: '/online-marketing/convert-list',
				component: () => import('../views/online-marketing/convert-list'),
				meta: {
					title: '兑换码管理',
					classify: 3,
                    auth: [''],
				},
			},
            {
				path: '/online-marketing/convert-edit',
				component: () => import('../views/online-marketing/convert-edit'),
				meta: {
					title: '编辑兑换码',
					classify: 3,
                    auth: [''],
                    hidden: true,
				},
			},
            {
				path: '/online-marketing/convert-detail',
				component: () => import('../views/online-marketing/convert-detail'),
				meta: {
					title: '兑换码详情',
					classify: 3,
                    auth: [''],
                    hidden: true,
				},
			},
            {
				path: '/online-marketing/discount-list',
				component: () => import('../views/online-marketing/discount-list'),
				meta: {
					title: '优惠券管理',
					classify: 3,
                    auth: [''],
				},
			},
            {
				path: '/online-marketing/discount-edit',
				component: () => import('../views/online-marketing/discount-edit'),
				meta: {
					title: '编辑优惠券',
					classify: 3,
                    auth: [''],
                    hidden: true,
				},
			},
            {
				path: '/online-marketing/discount-detail',
				component: () => import('../views/online-marketing/discount-detail'),
				meta: {
					title: '优惠券详情',
					classify: 3,
                    auth: [''],
                    hidden: true,
				},
			},
		]
    },
    {  // 在线教育 设置
        path: '/online-set',
		component: Layout,
        redirect: '/online-set/advertising-list',
		meta:{
            roles: [PROVIDER],
			title: '设置',
			classify: 3,
            icon: 'icon-set',
            auth: [''],
		},
		children: [
			{
				path: '/online-set/advertising-list',
				component: () => import('../views/online-set/advertising-list'),
				meta: {
					title: '小程序轮播图管理',
					classify: 3,
                    auth: [''],
				},
			},
			{
				path: '/online-set/advertising-edit',
				component: () => import('../views/online-set/advertising-edit'),
				meta: {
					title: '编辑轮播图',
					classify: 3,
                    auth: [''],
                    hidden: true,
				},
			},
		]
    },

    {  // 系统管理 个性化设置
        path: '/system-set',
        component: Layout,
        redirect: '/system-set/logo-set',
        meta: {
            roles: [ORG],
            title: '个性化设置',
            classify: 4,
            icon: 'icon-set',
            auth: [''],
        },
        children: [
            {
                path: '/system-set/logo-set',
                component: () => import('../views/system-set/logo'),
                meta: {
                    roles: [ORG],
                    title: 'Logo设置',
                    classify: 4,
                    auth: [''],
                }
            },
        ]
    },
    {  // 系统管理 服务订阅
        path: '/system-service',
        component: Layout,
        redirect: '/system-service/service-list',
        meta: {
            roles: [SCHOOL, ORG, ORG_SCHOOL],
            title: '服务订阅',
            classify: 4,
            icon: 'icon-service',
            auth: [''],
        },
        children: [
            {
                path: '/system-service/service-list',
                component: () => import('../views/system-service/service-list'),
                meta: {
                    roles: [SCHOOL, ORG, ORG_SCHOOL],
                    title: '服务订阅',
                    classify: 4,
                    auth: [''],
                },
            },
            {
                path: '/system-service/service-detail',
                component: () => import('../views/system-service/service-detail'),
                meta: {
                    roles: [SCHOOL, ORG, ORG_SCHOOL],
                    title: '服务订阅',
                    classify: 4,
                    auth: [''],
                    hidden: true,
                },
            },
            {
                path: '/system-service/service-record',
                component: () => import('../views/system-service/service-record'),
                meta: {
                    roles: [SCHOOL, ORG, ORG_SCHOOL],
                    title: '服务订阅',
                    classify: 4,
                    auth: [''],
                    hidden: true,
                },
            },
        ]
    },

    { // 路由重定向
        path: '/redirect',
        meta: {
            title: '公共部件',
            classify: '0',
            hidden: true,
        },
        chlidren: [
            {
                path: '/resources/list',
                redirect: '/resources/resources-list',
            },
            {
                path: '/plan/list/provider',
                redirect: '/plan/provider-plan-list',
            },
            {
                path: '/plan/list/school',
                redirect: '/plan/school-plan-list',
            },
            {
                path: '/plan/detail',
                redirect: '/plan/plan-detail',
            },
        ],
    },
];

export default routes
