import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Message } from 'element-ui';
import Core from 'core';
import store from '../store'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

import routes from './routes'

const router = new VueRouter({
    routes
});

NProgress.configure({
    easing: 'ease', // 动画方式
    speed: 500, // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
});

router.beforeEach((to, from, next) => {
    if (to.matched.length === 0) {
        NProgress.done();
        next({ path: '/login' })
    }
    if (to.path !== '/plan/schedule-print') {
        NProgress.start();
    }
    if (to.path === '/login' || to.path === '/register' || to.path === '/plan/schedule-print') {
        // 去登录页面直接放行
        NProgress.done();
        next();
    } else {
        const token = Core.Data.getToken();
        if (!token) {
            // 没登录
            Message({type: 'error',message: '请先登录！'});
            NProgress.done();
            next('/login');
        } else {
            const userType = Core.Data.getUserType();
            // 已登录 验证功能权限
            if( userType === Core.Const.USER_TYPE.SCHOOL) {
                // 如果登录用户是学校方
                let classify = to.meta?.classify || ''

                switch (classify) {
                    case 1:
                        if (Core.Utils.auth('EDUCATION')) {
                            next();
                        } else {
                            Message({ type: 'error', message: '当前身份无法访问' });
                            next('/login');
                        }
                        break;
                    case 2:
                        if (Core.Utils.auth('OPERATION')) {
                            next();
                        } else {
                            Message({type: 'error',message: '当前身份无法访问'});
                            next('/login');
                        }
                        break;
                    default :
                        next();
                        break;
                }
                NProgress.done();
            } else {
                 // 如果登录用户是资源方 或 机构
                next();
            }
            // 已登录 验证身份权限
            const roles = to.meta.roles;
            if (roles) {
                // 如果进入的路由meta中有roles规则
                if (roles.indexOf(userType) > -1) {
                    // 如果当前usertType在roles arr中有
                    next();
                } else {
                    // 表面当前userType禁止访问
                    Message({type: 'error',message: '当前身份无法访问'});
                    next('/login');
                    NProgress.done();
                }
            } else {
                // 没有roles规则直接放行
                next();
            }
        }
    }
});

router.afterEach((to, from) => {
    NProgress.done();
    let classify = to.meta?.classify || 1
    Core.Data.setSystemIndex(classify)
});

export default router;
