<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
import Core from 'core';

export default {
    created() {
    },
    mounted() {
    },
    methods: {
    }
};
</script>
<style lang="scss">
html {
    font-size: $font-size-base;
    color: $color-common;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
h1,h2,h3,h4,h5,h6,input,textarea,p,span{
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
</style>
