import * as types from './mutation-types';
// import Const from '../core/const'

export default {
    [types.SET_USER](state, val) {
        state.user = val;
    },
    [types.SET_SCHEDULE_DATA](state, { index, val }) {
        state.scheduleData[index].week = val;
    },
    [types.SET_ADD_SCHEDULE_DATA_ITEM](state, { name, length }) {
        const item = {
            name,
            beginTime: null,
            endTime: null,
            id: state.scheduleData.length + 1,
            week: new Array(length).fill({key: ''})
        }
        const scheduleData = [...state.scheduleData, item];
        state.scheduleData = scheduleData;
    },
    [types.SET_DELETE_SCHEDULE_DATA_ITEM](state, { index }) {
        state.scheduleData.splice(index, 1)
    },
    [types.SET_REPLACE_SCHEDULE_DATA](state, val) {
        state.scheduleData = val
    },
    [types.SET_RETSET_SCHEDULE_DATA](state) {
        const scheduleData = [
            {
                name: '区域活动',
                beginTime: '8:00',
                endTime: '9:00',
                id: 1,
                week: [
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                ]
            },
            {
                name: '韵律时光',
                beginTime: '9:10',
                endTime: '9:30',
                id: 2,
                week: [
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                ]
            },
            {
                name: '主题活动',
                beginTime: '9:50',
                endTime: '10:10',
                id: 3,
                week: [
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                ]
            },
            {
                name: '运动时光',
                beginTime: '10:10',
                endTime: '11:00',
                id: 4,
                week: [
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                ]
            },
            {
                name: '睡前活动',
                beginTime: '11:40',
                endTime: '12:00',
                id: 5,
                week: [
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                ]
            },
            {
                name: '区域活动',
                beginTime: '15:00',
                endTime: '15:30',
                id: 7,
                week: [
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                ]
            },
            {
                name: '萌学时光',
                beginTime: '15:30',
                endTime: '15:50',
                id: 6,
                week: [
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                    { key: '' },
                ]
            },
        ]
        state.scheduleData = scheduleData
    },
    [types.SET_RESOURCE_CLIPBOARD](state, val) {
        const resourceClipboard = JSON.parse(JSON.stringify(val))
        state.resourceClipboard = resourceClipboard
    },
    [types.SET_SYSTEM_INDEX](state, val) {
        state.systemIndex = val
    },
    // [changelogoUrl](state,val){
    //     state.logoUrl = val
    // }
};
