<template>
<div class="login-container" v-loading="loading">
    <div class="login-header">
        <div class="logo">
            <img :src="logoUrl" alt="梨子助教" />
        </div>
    </div>
    <div class="login-form">
        <div class="login-form-content" v-show="!identityChoiceShow && !schoolChoiceShow">
            <div class="form-title">账号登录</div>
            <div class="form-subTitle">你好，欢迎使用梨子助教备课系统~</div>
            <div class="form-group">
                <el-input class="icon-input form-group-item" size="medium"
                    v-model="loginForm.phone" placeholder="请输入您的手机号" autocomplete="off">
                    <!-- <img style="width:20px;height:20px;margin:12px 0 0 10px;"
                        src="../assets/images/icon-input-user.png"> -->
                    <i slot="prefix" class="prefix iconfont icon-user"></i>
                </el-input>
                <el-input class="icon-input form-group-item" size="medium"
                    v-model="loginForm.password" type="password" placeholder="请输入您的密码"
                    @keyup.enter.native="handleLogin" autocomplete="off">
                    <!-- <img slot="prefix" style="width:20px;height:20px;margin:13px 0 0 10px;"
                        src="../assets/images/icon-input-password.png"> -->
                    <i slot="prefix" class="prefix iconfont icon-lock"></i>
                </el-input>
                <el-button class="login-btn" type="success" size="medium" @click="handleLogin">登 录</el-button>
                <el-button class="register-btn" type="text" size="medium" @click.stop="routerChange(1)" round v-if='canRegister'>
                    <span class="color">还没账号？</span>立即注册
                </el-button>
            </div>
        </div>
        <div class="login-identity-choice" v-show='identityChoiceShow'>
            <!-- 选择登陆身份 -->
            <div class="title" @click="identityChoiceShow = false">选择您的身份</div>
            <div class="identity-type-list">
                <div class="type-item" @click="handleIdentityChoice(USER_TYPE.SCHOOL)"
                    :class="{'active': identity === USER_TYPE.SCHOOL}" v-if="identityList.school_account">
                    <div class="icon">
                        <img src="../assets/images/login-account-school.png" />
                    </div>
                    <div class="desc">
                        <p class="identity-type">园区方</p>
                    </div>
                </div>
                <div class="type-item" @click="handleSchoolIdentityChoice()" :class="{'active': schoolChoiceShow}"
                    v-if="identityList.org_school_account">
                    <div class="icon">
                        <img src="../assets/images/login-account-school.png" />
                    </div>
                    <div class="desc">
                        <p class="identity-type">园区方</p>
                    </div>
                </div>
                <div class="type-item" @click="handleIdentityChoice(USER_TYPE.ORG)"
                    :class="{'active': identity === USER_TYPE.ORG}" v-if="identityList.org_account">
                    <div class="icon">
                        <img src="../assets/images/login-account-org.png" />
                    </div>
                    <div class="desc">
                        <p class="identity-type">机构方</p>
                    </div>
                </div>
                <div class="type-item" @click="handleIdentityChoice(USER_TYPE.PROVIDER)"
                    :class="{'active': identity === USER_TYPE.PROVIDER}" v-if="identityList.provider_account">
                    <div class="icon">
                        <img src="../assets/images/login-account-provider.png" />
                    </div>
                    <div class="desc">
                        <p class="identity-type">资源方</p>
                    </div>
                </div>
                <div class="type-item" @click="handleIdentityChoice(USER_TYPE.ADMIN)"
                    :class="{'active': identity === USER_TYPE.ADMIN}" v-if="identityList.admin_account">
                    <div class="icon">
                        <img src="../assets/images/login-account-admin.png" />
                    </div>
                    <div class="desc">
                        <p class="identity-type">平台方</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="login-school-choice" v-show='schoolChoiceShow'>
            <!-- 机构选择登陆学校 -->
            <el-button class="back-btn" @click="identityChoiceShow = true, schoolChoiceShow = false">返回</el-button>
            <div v-if="orgSchoolList.length === 0">
                <no-data desc='当前机构下暂无学校' />
            </div>
            <template v-else>
                <div class="title">选择要查看的学校</div>
                <div class="school-list">
                    <div class="list-item" :class="{'active': selectSchoolId === item.id}"
                        v-for="(item,index) in orgSchoolList" :key="index" @click="handleSchoolChoice(item)">
                        {{item.name}}
                    </div>
                </div>
                <div class="page-change">
                    <el-pagination layout="pager" small class="point-pagination" hide-on-single-page
                        @current-change="pageChange" :current-page="schoolPage" :total="schoolCount" :page-size="5">
                    </el-pagination>
                </div>
            </template>
        </div>
    </div>
    <div class="login-footer">
        <span>Copyright © 2020-2021 Rebuildsoft. All rights reserved</span>
        <powered></powered>
    </div>
</div>
</template>

<script>
import Core  from '../core';
import Const from '../core/const';

import LOGO_URL from '../assets/images/Logo.png'

const USER_TYPE = Const.USER_TYPE;
export default {
    components: {
        Powered: () => import('@/components/Powered'),
        NoData: () => import('@/components/Empty')
    },
    data() {
        return {
            USER_TYPE,
            loading: false,

            loginForm: {
                phone: '',
                password: ''
            },
            user_id: '',
            org_id: '',

            identityChoiceShow: false,
            identityList: {},
            org_school_account: {},
            identity: '',

            schoolChoiceShow: false,
            selectSchoolId: '',
            orgSchoolList: [],
            schoolPage: 1,
            schoolCount: 0,

            logoUrl:'',

            canRegister: false,
        };
    },
    created() {
        let host = window.location.hostname;
        if (host != 'course.school.rebuildsoft.com') {
            this.canRegister = true;
        }
        let logoUrl =  Core.Data.getLogoUrl()
        this.logoUrl = logoUrl ? logoUrl : LOGO_URL
    },
    mounted() {
        if (this.$route.query.user_id) {
            this.user_id = Number(this.$route.query.user_id);
            this.getIdentityList(this.user_id);
        }
    },
    methods: {
        routerChange(key, q = '') {
            switch (key) {
                case 1:
                    this.$router.push('/register');
                    break;
            }
        },
        // 登录 获取账号ID
        handleLogin() {
            if (!this.loginForm.phone.trim()) {
                return this.$message.warning('请输入手机号!');
            }
            if (!this.loginForm.password.trim()) {
                return this.$message.warning('请输入密码!');
            }
            this.loading = true;
            Core.Api.UserLogin.login(
                this.loginForm.phone,
                this.loginForm.password
            ).then(res => {
                console.log('handleLogin res', res);

                this.user_id = res.user.id;
                this.getIdentityList(this.user_id);
            }).finally(() => {
                this.loading = false;
            });
        },
        // 通过账号ID 获取身份列表
        getIdentityList(user_id) {
            this.loading = true;
            Core.Api.UserLogin.identityList(user_id).then(res => {
                console.log('getIdentityList res', res);
                this.identityList = res.user_account;
                if (this.identityList.org_account) {
                    this.identityList.school_account = null;
                    this.identityList.org_school_account = true;

                    this.org_school_account = {
                        org_id: this.identityList.org_account.org_id,
                        role_id: this.identityList.org_account.role
                    };
                }
                this.identityChoiceShow = true;
            }).finally(() => {
                this.loading = false;
            });
        },
        // 确定登录身份 获取token
        handleIdentityChoice(type) {
            this.identity = type;
            if (!this.identity) return this.$message.warning('请选择登录身份!');
            this.loading = true;
            Core.Api.UserLogin.getToken(
                this.identity, this.user_id
            ).then(res => {
                console.log('handleIdentityChoice res', res);
                Core.Data.setToken(res.token);
                Core.Data.setUserType(this.identity);
                Core.Data.setUser(res.user);

                switch (this.identity) {
                    case USER_TYPE.PROVIDER:
                        Core.Api.ProviderAccount.detail().then(result => {
                            console.log('ProviderAccount', result)
                            Core.Data.setBelong(result.provider_member.provider);
                            this.getAccountAuth(result.provider_member);
                        });
                        break;
                    case USER_TYPE.SCHOOL:
                        Core.Data.setProvider(res.provider.name);
                        Core.Api.SchoolAccount.detail().then(result => {
                            console.log('SchoolAccount',result);
                            Core.Data.setBelong(result.school_account.school);
                            this.getAccountAuth(result.school_account);
                        });
                        break;
                    case USER_TYPE.ORG:
                        Core.Data.setProvider(res.provider.name);
                        Core.Data.setBelong(res.org);
                        Core.Api.OrgAccount.detail().then(result => {
                            console.log('OrgAccount',result);
                            this.getAccountAuth(result.detail);
                        });
                        break;
                    case USER_TYPE.ADMIN:
                        Core.Data.setBelong({});
                        this.getAccountAuth({});
                        break;
                }
            }).catch(err => {
                console.log('handleIdentityChoice err:', err)
                this.loading = false;
            });
        },
        async getAccountAuth(account) {
            console.log('getAccountAuth account:', account)
            let authList = []
            if (account.role_id) {
                let res = await Core.Api.Authority.roleAuthorityList(account.role_id)
                authList = res.list
            }
            let nav = {
                name: '资源列表',
                path: '/resources/resources-list',
                classify: 1
            }
            if (account.type) {
                authList.push({ name: '组织管理员', key: 'MANAGER' },)
            }
            switch (this.identity) {
                case USER_TYPE.PROVIDER:
                    authList.push(
                        { name: '用户类型', key: 'PROVIDER' },
                        { name: '教学管理', key: 'EDUCATION' },
                        // { name: '运营管理', key: 'OPERATION' }
                    )
                    break;
                case USER_TYPE.SCHOOL:
                    authList.push({ name: '用户类型', key: 'SCHOOL' })
                    let {education_manage, operation_manage} = account.school
                    if (operation_manage) {
                        authList.push({ name: '运营管理', key: 'OPERATION' })
                        nav = {
                            name: '邀请列表',
                            path: '/operate-quotient/invite-list',
                            classify: 2
                        }
                    }
                    if (education_manage) {
                        authList.push({ name: '教学管理', key: 'EDUCATION' })
                        nav = {
                            name: '资源列表',
                            path: '/resources/resources-list',
                            classify: 1
                        }
                    }
                    if (!education_manage && !operation_manage) {
                        return this.$message.warning('您所在的学校还未分配功能权限');
                    }
                    let {detail: {service_type}} = await await Core.Api.Org.detail(account.school.org_id)
                    if (service_type == 2) {
                        authList.push({ name: '机构服务类型', key: 'LIMIT_SERVICE' })
                    }
                    break;
                case USER_TYPE.ORG:
                    authList.push(
                        { name: '用户类型', key: 'ORG' },
                        { name: '教学管理', key: 'EDUCATION' },
                        { name: '运营管理', key: 'OPERATION' }
                    )
                    if (account.org.type === 2) {
                        authList.push(
                            { name: '机构类型', key: 'CHANNE_CUSTOMERS' }
                        )
                    }
                    break;
                case USER_TYPE.ADMIN:
                    authList.push(
                        { name: '用户类型', key: 'ADMIN' },
                        { name: '教学管理', key: 'EDUCATION' },
                        { name: '运营管理', key: 'OPERATION' }
                    )
                    nav = {
                        name: '机构管理',
                        path: '/org/org-list',
                        classify: 1
                    }
                    break;
            }
            console.log('getAccountAuth authList:', authList)
            Core.Data.setUserAuth(authList);
            Core.Data.setBreadcrumbNav(nav);
            setTimeout(() => {
                this.loading = false;
                this.$router.replace({ path: nav.path });
            }, 100);
        },

        handleSchoolIdentityChoice(item) {
            Core.Api.UserLogin.getToken(
                USER_TYPE.ORG,
                this.user_id
            ).then(res => {
                console.log('handleSchoolIdentityChoice -> res', res);
                Core.Data.setToken(res.token);
                Core.Data.setUserType(USER_TYPE.ORG);
                Core.Data.setUser(res.user);

                Core.Data.setProvider(res.provider.name);
                Core.Data.setBelong(res.org);

                this.schoolChoiceShow = true;
                this.identityChoiceShow = false;

                this.org_id = res.org.id;
                Core.Api.Authority.roleAuthorityList(
                    this.org_school_account.role_id
                ).then(result => {
                    console.log('getRoleAuthorityList result', result);
                    let authList = result.list
                    authList.push({ name: '用户类型', key: 'ORG_SCHOOL' })
                    console.log('authList:', authList)
                    Core.Data.setUserAuth(authList);
                }).catch((err) => {
                    console.log('getRoleAuthorityList error', err);
                });
                this.getOrgSchoolList();
            });
        },

        getOrgSchoolList() {
            Core.Api.Org.listSchool(this.org_id, this.schoolPage, 5).then(
                (res) => {
                    console.log('getOrgSchoolList', res);
                    this.schoolCount = res.count;
                    this.orgSchoolList = res.list;
                }
            );
        },
        pageChange(page) {
            this.schoolPage = page;
            this.getOrgSchoolList();
        },
        handleSchoolChoice(item) {
            this.loading = true;
            this.selectSchoolId = item.id;
            console.log('handleSchoolChoice -> item', item);
            Core.Api.OrgAccount.selectSchool(item.id).then(res => {
                console.log('handleSchoolChoice -> res', res);
                Core.Data.setToken(res.token);
                Core.Data.setOrgCurrSchool(res.school);
                Core.Data.setUserType(USER_TYPE.ORG_SCHOOL);
                let {education_manage, operation_manage} = res.school
                let nav
                let authList = Core.Data.getUserAuth()
                console.log('authList:', authList)
                if (operation_manage) {
                    authList.push({ name: '运营管理', key: 'OPERATION' })
                    nav = {
                        name: '邀请列表',
                        path: '/operate-quotient/invite-list',
                        classify: 2
                    }
                }
                if (education_manage) {
                    authList.push({ name: '教学管理', key: 'EDUCATION' })
                    nav = {
                        name: '资源列表',
                        path: '/resources/resources-list',
                        classify: 1
                    }
                }
                if (!education_manage && !operation_manage) {
                    return this.$message.warning('该学校尚未分配功能权限');
                }
                Core.Data.setBreadcrumbNav(nav);
                setTimeout(() => {
                    this.loading = false;
                    this.$router.replace({ path: nav.path});
                }, 1000);

            }).catch((err) => {
                console.log('handleSchoolChoice -> err', err);
            });
        },
    }
};
</script>

<style lang="scss" scoped>
.login-container {
    width: 100%;
    height: 100vh;
    min-height: 570px;
    overflow-y: auto;
    background: #eff1f5;
    box-sizing: border-box;
    position: relative;
    .login-header {
        width: 100%;
        height: 60px;
        background-color: #fff;
        @include ftb;
        .logo {
            @include ftb;
            @include no-select;
            cursor: pointer;
            padding-left: 20px;
            img {
                height: 33px;
                margin-right: 10px;
            }
            .name {
                font-size: 12px;
                color: #6e767d;
            }
        }
    }
    .login-form {
        width: 100%;
        height: calc(100% - 160px);
        @include fct;
        z-index: 2;
        position: relative;
        .login-form-content {
            width: 600px;
            height: 452px;
            box-sizing: border-box;
            background: #fff;
            border-radius: 6px;
            border: 1px solid rgba(234, 237, 242, 1);
            overflow: hidden;
            text-align: center;
            .form-title {
                height: 70px;
                line-height: 70px;
                background: rgba(248, 250, 252, 1);
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                color: rgba(53, 60, 68, 1);
            }
            .form-subTitle {
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                color: rgba(140, 146, 155, 1);
                line-height: 20px;
                margin-top: 34px;
                margin-bottom: 40px;
            }
            .form-group {
                margin: 40px 40px 24px;
                .form-group-item {
                    margin-bottom: 24px;
                    width: 400px;
                }
            }
            .login-btn {
                width: 400px;
                height: 50px;
            }
            .register-btn {
                width: 90%;
                text-align: center;
                font-size: 13px;
                color: #74be5f;
                .color {
                    color: #8c929b;
                }
            }
            .type-change {
                text-align: right;
            }
            .qrcode-container {
                background: #fff;
            }
        }
    }
    .login-footer {
        z-index: 1;
        position: relative;
        @include flex(column, flex-start, center);
        height: 100px;
        span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(55, 59, 65, 1);
            line-height: 20px;
        }
    }
}
.login-identity-choice {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
        font-size: 38px;
        font-weight: 500;
        color: #050505;
        margin-bottom: 38px;
    }
    .identity-type-list {
        width: 100%;
        display: flex;
        justify-content: center;
        .type-item {
            + .type-item {
                margin-left: 32px;
            }
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            box-sizing: border-box;
            width: 288px;
            max-width: 30%;
            padding: 57px 20px 40px;
            background: #ffffff;
            box-shadow: 0px 6px 14px 0px rgba(1, 108, 255, 0.06);
            border-radius: 6px;
            border: 2px solid transparent;
            transition: background-color 0.3s linear, border-color 0.3s linear;
            &.active {
                background: #eff6ff;
                box-shadow: 0px 8px 8px 0px rgba(177, 200, 248, 0.24);
                border: 2px solid #006aff;
            }

            .icon {
                width: 74px;
                height: 74px;
                img {
                    width: 100%;
                }
            }
            .desc {
                margin-top: 45px;
                text-align: center;
                .identity-type {
                    font-size: 16px;
                    font-weight: 500;
                    color: #000000;
                }
                .account-name {
                    font-size: 14px;
                    font-weight: 400;
                    color: #a0a0a0;
                    margin-top: 8px;
                }
            }
        }
    }
    .confirm-btn {
        margin-top: 30px;
    }
}
.login-school-choice {
    .title {
        font-size: 38px;
        font-weight: 500;
        color: #050505;
        margin-bottom: 32px;
        text-align: center;
    }
    .back-btn {
        position: absolute;
        top: 20px;
        right: 36px;

        padding: 0 30px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #d9dce0;
        height: 46px;
        line-height: 42px;
        font-weight: 500;
        font-size: 18px;
        color: #006aff;

        cursor: pointer;
    }
    .school-list {
        // height: 364px;
        .list-item {
            + .list-item {
                margin-top: 16px;
            }
            width: 380px;
            height: 56px;
            background: #ffffff;
            box-shadow: 0px 6px 6px 0px rgba(241, 241, 243, 0.1);
            border-radius: 6px;
            border: 2px solid #ffffff;
            &.active {
                background: #eff6ff;
                box-shadow: 0px 8px 8px 0px rgba(177, 200, 248, 0.24);
                border: 2px solid #006aff;
            }
            padding: 0 20px;
            color: #3f4549;
            font-size: 16px;
            display: flex;
            align-items: center;
        }
    }
}
</style>
<style lang="scss">
.icon-input.el-input {
    .el-input__inner {
        height: 46px;
        line-height: 46px;
    }
    .el-input__prefix {
        line-height: 46px;
        width: 30px;
        .prefix {
            font-size: 20px;
        }
    }
}
.login-school-choice {
    .page-change {
        margin-top: 42px;
        display: flex;
        justify-content: center;
        .point-pagination {
            ul.el-pager {
                li.number {
                    + li.number {
                        margin-left: 12px;
                    }
                    font-size: 0;
                    padding: 0;
                    width: 12px;
                    min-width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background: #ffffff;
                    &.active {
                        background: #006aff;
                    }
                }
            }
        }
    }
}
</style>
