<template>
    <el-container>
        <el-header style="height: 60px;">
            <MyHeader/>
        </el-header>
        <el-container>
            <el-aside width="240px">
                <MySidebar ref="sidebar" />
            </el-aside>
            <el-main ref='elMain'>
                <BreadcrumbNav/>
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Core  from '../../core';
import Const from '../../core/const';

const USER_TYPE = Const.USER_TYPE;

export default {
    components: {
        MyHeader: () => import('./components/Header'),
        MySidebar: () => import('./components/Sidebar'),
        BreadcrumbNav: () => import('./components/BreadcrumbNav'),
    },
    data() {
        return {
            USER_TYPE,
            userType: Core.Data.getUserType(),
        }
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
.el-container {
    height: 100%;
}
.el-header {
    background-color: #fff;
    color: #333;
    @include border-bottom-1px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    padding: 0;
    z-index: 10;
}
.el-aside {
    width: 240px;
    display: block;
    position: absolute;
    left: 0;
    top: 60px;
    bottom: 0;
    overflow-y: auto;
    border-right: solid 1px #e6eaee;
    background: #fff;
    padding: 30px 10px;
}
.el-main {
    display: block;
    position: absolute;
    left: 240px;
    top: 60px;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    background: #f1f4f9;
    padding: 0;
}
</style>