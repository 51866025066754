import Const from '../const'

let host = window.location.hostname;
let eduBaseUrl = Const.NET.URL_POINT_DEV;
let operBaseUrl = Const.NET.CLIENT_URL_POINT_DEV;

switch (host) {
    case 'localhost':
    case '10.0.0.222':
    case '10.0.0.249':
	case '10.0.0.197':
	case '10.0.0.210':
	case '10.0.0.127':
	case '10.0.0.138':
    case '10.0.0.191':
    case '10.0.0.194':
    case '10.0.0.244':
    case '10.0.0.227':
    case '192.168.110.138':
    case '192.168.31.64':
    case '192.168.31.245':
    case '10.0.0.230':
    case '10.0.0.120':
    case '10.0.0.135':
    case '10.0.0.67':
		// eduBaseUrl = `http://${host}:8083`
        // eduBaseUrl = `http://192.168.188.216:8083`
        eduBaseUrl = Const.NET.URL_POINT
		operBaseUrl = Const.NET.CLIENT_URL_POINT;
        break;
    case 'course.school.rebuildsoft.com':
    case 'admin.school.rebuildsoft.com':
        eduBaseUrl = Const.NET.URL_POINT
        operBaseUrl = Const.NET.CLIENT_URL_POINT;
        break;
    case 'course.dev.school.rebuildsoft.com':
        eduBaseUrl = Const.NET.URL_POINT
        operBaseUrl = Const.NET.CLIENT_URL_POINT;
        break;
    case 'demo.course.school.rebuildsoft.com':
        eduBaseUrl = Const.NET.URL_POINT_DEMO
        operBaseUrl = Const.NET.CLIENT_URL_POINT_DEV;
        break;
}

export const baseUrl = eduBaseUrl
export const operationBaseUrl = operBaseUrl
