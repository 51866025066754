import axios from 'axios'
import router from '../../../router/index'
import { Message } from 'element-ui'

const tip = msg => {
    Message({
        type: 'error',
        message: msg
    })
}

const toLogin = () => {
    router.replace({
        path: '/login',
        // query: {
        //     redirect: router.currentRoute.fullPath
        // }
    })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number}   请求失败的状态码
 */
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        // 401: 未登录状态，跳转登录页
        case 2: {
            console.log('other :', other);
            if (other === 'invalid token') {
                tip('请先登录')
                toLogin()
            }
        }
            break
        default:
            tip(`${other}`)
    }
}

var instance = axios.create({ timeout: 1000 * 12 })

instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

instance.interceptors.request.use(
    config => {
        return config
    },
    error => Promise.error(error)
)

instance.interceptors.response.use(
    (res) => {
        if (res.status === 200) {
            if (res.data.code === 0) {
                return res.data.data;
            } else {
                errorHandle(res.data.code, res.data.message);
                return Promise.reject(res);
            }
        } else {
            Promise.reject(res);
        }
    },
    (error) => {
        const { response } = error;
        if (response) {
            errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            if (window.navigator.onLine) {
                return Promise.reject(error);
            }
        }
    }
);

export default instance
