import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import filters from './core/filters/index.js'
import ElementUI from 'element-ui';
import JsonViewer from 'vue-json-viewer';
import Contextmenu from "vue-contextmenujs";
import VueClipboard from 'vue-clipboard2' ;
import VueQriously from 'vue-qriously';
import VideoPlayer from 'vue-video-player';

import './common/iconfont/iconfont.css';
import './common/styles/normalize.scss';
import './common/styles/reset.scss';
import './common/styles/common.scss';
import './common/styles/element-variables.scss';

import 'video.js/dist/video-js.css';
require('vue-video-player/src/custom-theme.css');
require('./common/styles/video.css');

Vue.use(ElementUI, { size: 'small' });
Vue.use(JsonViewer);
Vue.use(Contextmenu);
Vue.use(VueClipboard);
Vue.use(VueQriously);
Vue.use(VideoPlayer);

Vue.config.productionTip = false

// 全局过滤器
Object.keys(filters).forEach(filterName => {
    Vue.filter(filterName, filters[filterName])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
