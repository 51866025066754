import Api from './base';
import {operationBaseUrl} from './config'

const apiList = {
    Invite: {
        paging: ['record-invitation/paging','user_name','user_phone','page','page_size'],
        save: ['record-invitation/save','id','user_name','user_phone','remark'],
        delete: ['record-invitation/delete','id'],
    },
    Record: {
        // paging: ['invitation-profile/paging','name','user_phone','page','page_size'],
        paging: ['record-invitation/paging-finished','user_name','user_phone','page','page_size'],
        delete: ['customer/delete','id'],
        detail: ['customer/detail','id'],
        evaluationResult: ['record/detail','id'],
        evaluationDetail: ['record/evaluation-data-detail','evaluation_data_id']
    },
    Evaluation: {
        list: ['record-invitation-activity/list', 'page', 'page_size'],
        save: ['record-invitation-activity/save', 'id', 'name', 'count', 'form_field','playbill_url', 'playbill_config', 'experience_courses', 'channel'],
        detail: ['record-invitation-activity/detail', 'id'],
        paging: ['record-invitation/paging', 'activity_id', 'user_name', 'user_phone', 'page', 'page_size'],
    },
    OperSet: { // 运营设置
        channelList: ['promotion-channel/list', 'page', 'page_size'],
        channelSave: ['promotion-channel/save', 'id', 'name'],
        channelDelete: ['promotion-channel/delete', 'id'],

        courseList: ['experience-course/list', 'page', 'page_size'],
        courseSave: ['experience-course/save', 'id', 'name'],
        courseDelete: ['experience-course/delete', 'id'],
    },
    Order: {
		createInvitationOrder: ['operation-order/create-invitation-order', 'school_id', 'price', 'charge', 'invitation_count'],
		update: ['operation-order/update', 'id', 'price', 'charge', 'invitation_count'],
		pay: ['operation-order/pay', 'sn', 'pay_type', 'user_id'],
		list: ['operation-order/list', 'page', 'page_size', 'school_id', 'type', 'user_id', 'sn', 'begin_time', 'end_time'],
		detail: ['operation-order/detail', 'id'],
		refund: ['operation-order/refund', 'sn', 'money'],
	},
    Wx: {
        getMiniProgramCode: ['client/1/wx/mini-program-code','org_id']
    }
}

export default new Api(operationBaseUrl, apiList);
