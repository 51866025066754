<template>
    <div class="register-container">
        <div class="register-header">
            <div class="logo">
                <img src="../assets/images/Logo.png" alt="初本教育" />
                <div class="name">教学管理平台</div>
            </div>
        </div>
        <div class="register-form">
            <div class="register-form-content">
                <div class="form-title">免费注册</div>
                <div class="form-group">
                    <div class="group-item">
                        <div class="key">姓名</div>
                        <el-input v-model="registerForm.contact_name" placeholder="请输入姓名" class="value"></el-input>
                    </div>
                    <div class="group-item">
                        <div class="key">手机</div>
                        <el-input v-model="registerForm.contact_phone" placeholder="请输入手机号码"
                            class="value input-with-select">
                            <el-select style="width: 80px;" v-model="select" slot="prepend" placeholder="请选择">
                                <el-option label="+86" value="1"></el-option>
                            </el-select>
                        </el-input>
                    </div>
                    <div class="group-item">
                        <div class="key">验证码</div>
                        <el-input v-model="registerForm.phone_code" placeholder="请输入验证码" class="value">
                            <el-button slot="append" @click="getPhoneCode">立即获取</el-button>
                        </el-input>
                    </div>
                    <div class="group-item">
                        <div class="key">密码</div>
                        <el-input show-password v-model="registerForm.password" placeholder="请输入密码" class="value"></el-input>
                    </div>
                    <div class="group-item">
                        <div class="key">学校名称</div>
                        <el-input v-model="registerForm.name" placeholder="请输入学校名称" class="value"></el-input>
                    </div>
                    <div class="group-item">
                        <div class="key">省/市/区县</div>
                        <el-cascader class="value" placeholder="请选择大致地址" v-model="registerForm.roughly_address"
                            :options="addressOptions"></el-cascader>
                    </div>
                    <div class="group-item">
                        <div class="key">详细地址</div>
                        <el-input v-model="registerForm.detail_address" placeholder="请输入详细地址" class="value"></el-input>
                    </div>
                    <div class="agree">
                        <el-checkbox style="margin-right: 10px" v-model="checked"></el-checkbox>我已阅读并同意<span>《梨子助教服务协议》《个人信息保护政策》</span>
                    </div>
                    <el-button class="register-btn" type="success" size="medium" @click="handleRegister">注 册</el-button>
                    <div class="login" @click="routerChange(1)">已有账号？<span>立即登录</span></div>
                </div>
            </div>
        </div>
        <div class="register-footer">
            <span>Copyright © 2020 Rebuild Soft. All rights reserved</span>
            <MyPowered/>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Core from '../core'
export default {
    components: {
        MyPowered: () => import('@/components/Powered')
    },
    props: {},
    data() {
        return {
            registerForm: {
                name: '',
                contact_name: '',
                contact_phone: '',
                phone_code: '',
                password: '',
                roughly_address: [],
                detail_address: ''
            },
            addressOptions: [],
            select: '1',
            checked: false
        };
    },
    watch: {},
    computed: {},
    created() {
        this.getRoughlyAddressList();
    },
    mounted() {},
    methods: {
        getRoughlyAddressList() {
            axios.get('/ext/region-code.json').then((response) => {
                this.addressOptions = response.data;
            });
        },
        getPhoneCode() {
            if(!this.registerForm.contact_phone) {
                return this.$message.error('请输入手机号码');
            }
            if(!/^1[3456789]\d{9}$/.test(this.registerForm.contact_phone)) {
                return this.$message.error('手机号码输入有误');
            }
            Core.Api.Common.codeSend(this.registerForm.contact_phone).then(res => {
                this.$message.success('发送成功');
            })
        },
        handleRegister() {
            if(!this.registerForm.contact_name) {
                return this.$message.error('请输入姓名');
            }
            if(!this.registerForm.phone_code) {
                return this.$message.error('请获取验证码');
            }
            if(!this.registerForm.password) {
                return this.$message.error('请输入密码');
            }
            if(!this.registerForm.name) {
                return this.$message.error('请输入学校名称');
            }
            if(!this.registerForm.roughly_address[0]) {
                return this.$message.error('请选择大致地址');
            }
            if(!this.registerForm.detail_address) {
                return this.$message.error('请输入详细地址');
            }
            if(this.checked === false) {
                return this.$message.error('请勾选协议');
            }
            Core.Api.Common.register(
                this.registerForm.name,
                this.registerForm.contact_name,
                this.registerForm.contact_phone,
                this.registerForm.phone_code,
                this.registerForm.password,
                this.registerForm.roughly_address[0],
                this.registerForm.roughly_address[1],
                this.registerForm.roughly_address[2],
                this.registerForm.detail_address,
            ).then(res => {
                this.$message.success('注册成功');
                this.$router.replace('/login');
            })
        },
        routerChange(type) {
            switch(type) {
                case 1: 
                    return this.$router.replace('/login')
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.register-container {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background: #eff1f5;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .register-header {
        width: 100%;
        height: 60px;
        background-color: #fff;
        @include ftb;
        .logo {
            @include ftb;
            @include no-select;
            cursor: pointer;
            padding-left: 20px;
            img {
                width: 121px;
                height: 33px;
                margin-right: 10px;
            }
            .name {
                font-size: 12px;
                color: rgba(110, 118, 125, 1);
            }
        }
    }
    .register-form {
        width: 100%;
        @include fct;
        z-index: 2;
        position: relative;
        margin: 10vh 0;
        .register-form-content {
            width: 700px;
            height: 720px;
            box-sizing: border-box;
            background: #fff;
            border-radius: 6px;
            border: 1px solid #eaedf2;
            overflow: hidden;
            text-align: center;
            .form-title {
                height: 70px;
                line-height: 70px;
                background: rgba(248, 250, 252, 1);
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                color: rgba(53, 60, 68, 1);
            }
            .form-group {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;
                .group-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 20px;
                    .key {
                        width: 100px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #8090a6;
                    }
                    .value {
                        width: 448px;
                    }
                }
            }
            .agree {
                font-size: 12px;
                font-weight: 400;
                color: #a4a4a4;
                display: flex;
                align-items: center;
                span {
                    color: #74be5f;
                }
            }
            .register-btn {
                width: 538px;
                height: 60px;
                border-radius: 2px;
                font-size: 24px;
                font-weight: 500;
                color: #ffffff;
                margin-left: 12px;
                margin-top: 30px;
            }
            .login {
                font-size: 13px;
                font-weight: 400;
                color: #666666;
                margin-top: 23px;
                cursor: pointer;
                span {
                    color: #74be5f;
                }
            }
        }
    }
    .register-footer {
        width: 100%;
        z-index: 1;
        position: relative;
        @include flex(column, flex-start, center);
        height: 80px;
        span {
            font-size: 14px;
            font-weight: 400;
            color: rgba(55, 59, 65, 1);
            line-height: 20px;
        }
    }
}
</style>
<style lang="scss">
.form-group .group-item .value.el-input--small .el-input__inner {
    height: 46px;
    line-height: 46px;
}
.form-group .group-item .value.el-cascader .el-input .el-input__inner {
    height: 46px;
    line-height: 46px;
}
</style>