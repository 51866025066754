import Api from './base';
import {baseUrl} from './config'

const apiList = {
    Common: {
        codeSend: ['public/phone-verification-code-send', 'phone'],
        register: ['public/register-school', 'name', 'contact_name', 'contact_phone', 'phone_code', 'password', 'province', 'city', 'county', 'detail_address'],
    },
    Dictionary: {
        getItemList: ['dictionary/get-item-list', 'key']
    },
	User: {
        userList: ['user/list', 'page', 'page_size'],
        allUserList: ['user/list'],

        login: ['user/login-by-phone', 'phone', 'password'],
        passwordUpdate: ['user/update-password','old_password','new_password'],
        detailByPhone: ['user/detail-by-phone', 'phone'],
		listByClass: ['user/list-by-class', 'page', 'page_size', 'school_id', 'class_id', 'student_id'],
		addExpireTime: ['user/add-expire-time', 'user_id', 'time'],
		addExpireTime3: ['user/add-expire-time-3', 'student_account_id', 'user_id'],
		refundLastOrder: ['user/refund-last-order', 'user_id'],
		forceClose: ['user/force-close', 'user_id'],
	},
    Parent: {

    },
    AdminLogin: {
        login: ['admin/login', 'account', 'password']
    },
    UserLogin: {
        login: ['user/login-by-phone', 'phone', 'password'],
        // getToken: ['user/get-token', 'type', 'phone', 'password'],

        identityList: ['user/account-list', 'user_id'],
        getToken: ['user/get-token-by-user-id', 'type', 'user_id'],

        // TYPE_PROVIDER_ACCOUNT = 102  //资源方账号
        // TYPE_ORG_ACCOUNT = 202       //机构账号
        // TYPE_SCHOOL_ACCOUNT = 302    //学校账号
    },
    Org: {
        create: ['org/create', 'id', 'name', 'provider_id', 'contact_name', 'contact_phone', 'province', 'city', 'county', 'detail_address', 'password', 'type'],
        save:   ['org/save',   'id', 'name', 'provider_id', 'contact_name', 'contact_phone', 'province', 'city', 'county', 'detail_address'],

        list: ['org/paging',      'page', 'page_size', 'name', 'contact_name', 'contact_phone'],
        listByAdmin: ['org/list', 'page', 'page_size', 'name', 'contact_name', 'contact_phone', 'status'],

        listName: ['org/list-name', 'provider_id'], // 选择用 机构列表

        detail: ['org/detail', 'id'],
        delete: ['org/delete', 'id'],

        updateType: ['org/update-type', 'id', 'type'],
        updateServeType: ['org/update-service-type', 'id', 'service_type'],

        listSchool: ['school/paging', 'org_id', 'page', 'page_size'],
        bindSchool: ['org/bind-school', 'id', 'school_ids'],
        saveOrgConfig:['org/org-config/save','logo'],
        getOrgConfigDetail: ['org/org-config/detail'],

    },
	CourseOrder: {
        list: ['course-order/list', 'page', 'page_size', 'sn', 'course_id', 'user_id', 'status', 'pay_type', 'source_type', 'begin_time', 'end_time'],
        listByOrgId: ['course-order/list', 'page', 'page_size', 'org_id'],

        detail: ['course-order/detail', 'id'],
	},
    DownloadOrder: {
        list: ['download-order/list', 'page', 'sn', 'user_id', 'status', 'pay_type', 'begin_time', 'end_time'],
        detail: ['download-order/detail', 'id'],
        create: ['download-order/create', 'user_id', 'student_id', 'price', 'charge', 'source_type'],
        update: ['download-order/update-status', 'id', 'user_id', 'status', 'pay_type'],
        refundLastOrder: ['download-order/refund', "sn", "money"]
    },
    ProviderAccount: {
        save: ['provider-account/save', 'id', 'role', 'type', 'phone', 'password', 'name'],
        detail: ['provider-account/detail'],
        list: ['provider-account/list', 'page', 'page_size'],
        delete: ['provider-account/delete', 'id'],
        passwordUpdate: ['provider-account/update-password','id','password'],
    },
    OrgAccount: {
        detail: ['org-account/detail'],
        save: ['org-account/save', 'org_id', 'id', 'name', 'phone', 'password', 'role', 'type', 'account'],
        list: ['org-account/paging', 'org_id', 'page', 'page_size'],
        delete: ['org-account/delete', 'id', 'user_id'],

        selectSchool: ['org-account/select-school', 'school_id'],
        passwordUpdate: ['org-account/update-password','id','password'],
    },
    OrgSystem: {
        getSchoolAll: ['school/list-org', 'org_id', 'provider_id'],
        create: ['operation-order/create-expiration-time-order', 'price', 'charge', 'school_ids', 'unit_price', 'subscribe_type', 'subscribe_count'],
        orderList: ['operation-order/list', 'type', 'page', 'page_size'],
        orderDetail: ['operation-order/detail', 'id'],
    },
    SchoolAccount: {
        save: ['school-account/save', 'id', 'password', 'phone', 'school_id', 'role', 'type', 'name'],
        detail: ['school-account/detail'],
        list: ['school-account/paging', 'school_id', 'page', 'page_size'],
        delete: ['school-account/delete', 'id', 'user_id'],
        passwordUpdate: ['school-account/update-password','id','password'],
    },
    Resource: {
        save: ['resource/save', 'id', 'template_type', 'type', 'labels', 'name', 'config', 'files', 'topic', 'dir_id', 'sort'],
        delete: ['resource/delete', 'id'],
        multipleDelete: ['resource/multiple-delete', 'ids'],
        list: ['resource/paging', 'page', 'page_size', 'name', 'template_type', 'type', 'topic', 'dir_id', 'media_type'],
        detail: ['resource/detail', 'id'],
        moveToDir: ['resource/move-to-dir', 'resource_ids', 'dir_id'],
        saveFile: ['resource/save-file', 'id', 'dir_id', 'name', 'file', 'sort'],
    },
    ResourceFile: {
        save: ['resource-file/save', 'id', 'resource_id']
    },
    ResourceDir: {
        list: ['resource-dir/list', 'dir_id'],
        save: ['resource-dir/save', 'name', 'parent_id', 'sort'],
        delete: ['resource-dir/delete', 'dir_id_list'],
        rename: ['resource-dir/name-update', 'name', 'dir_id', 'sort'],
        tree: ['resource-dir/tree']
    },
    WeekPlan: {
        save: ['week-plan/save', 'id', 'week', 'year', 'name', 'begin_time', 'end_time', 'config', 'plan_id'],
        list: ['week-plan/paging', 'page', 'name', 'year', 'week', 'begin_time', 'end_time', 'page_size', 'plan_id', 'id'],
        detail: ['week-plan/detail', 'year', 'week', 'plan_id'],
        delete: ['week-plan/delete', 'id'],

        weekList: ['week-plan/get-year-week-list', 'plan_id', 'year'],
    },
    School: {
        save: ['school/save', 'id', 'name', 'contact_name', 'contact_phone', 'education_manage', 'operation_manage', "provider_id", "org_id"],
        list: ['school/paging', 'page', 'page_size', 'org_id', 'name', 'contact_name', 'contact_phone', 'status'],
        listSearchByName: ['school/paging', 'page', 'page_size', 'name'],
        delete: ['school/delete', 'id'],
        detail: ['school/detail', 'id'],

        listName: ['school/list-name'], // 选择用
        listByOrgId: ['school/list-org', 'org_id'],

        renewal: ['school/update-expiration-time', 'school_id', 'increased_time'],
        renewalRecord: ['school/expiration-time-record-list', 'page', 'page_size', 'school_id'],
    },
    SchoolClass: {
        list: ['school-class/paging', 'page', 'page_size', 'school_id', 'name'],
        detail: ['school-class/detail', 'id'],
        delete: ['school-class/delete', 'id'],
        teacherList: ['school-class/teacher-list', 'class_id'],
        teacherOut: ['school-class/kick-teacher-out', 'class_id','user_ids'],
        teacherBind: ['school-class/bind', 'class_id','user_ids'],
        setPlan: ['school-class/set-plan', 'class_id','plan_id'],
    },
    Student: {
        list: ['student/paging', 'page', 'page_size', 'org_id', 'school_id', 'class_id', 'status'],
        detail: ['student/detail-qrcode', 'id'],
        delete: ['student/delete', 'id'],
		setCameraAuthority: ['student/student-account/set-camera-authority', 'student_account_id'],
    },
    SchoolRecord: {
        create: ['school-record-invitation/create', 'school_id', 'increased_count', 'money'],
        list: ['school-record-invitation/list', 'school_id', 'page', 'page_size'],
		createByOrder: ['school-record-invitation/create-by-order', 'order_id'],
    },
    Plan: {
        list: ['plan/paging', 'page', 'page_size', 'name', 'year', 'type', 'school_id'],
        create: ['plan/create', 'name', 'year'],
        copy: ['plan/copy', 'source_id', 'year'],
        createBySchool: ['plan/create', 'name', 'year', 'parent_id'],
        update: ['plan/update', 'id', 'name'],
        delete: ['plan/delete', 'id'],
        detail: ['plan/detail', 'id'],
        sync: ['plan/sync','id'],
    },
    Comment: {
        save: ['record-template/save','id','type','content'],
        list: ['record-template/list','type'],
        delete: ['record-template/delete','id'],
        paging: ['record-template/paging','type','page','page_size']
    },
    Evaluation: {
        paging: ['month-record-template/paging','plan_id','month','page','page_size'],
        copy: ['month-record-template/copy','plan_id','source_id'],
        delete: ['month-record-template/delete','id'],
        save: ['month-record-template/save','id','plan_id','begin_time', 'end_time','topic_name','topic_target','month','config'],
        detail: ['month-record-template/detail', 'id'],
    },
    SchoolEvaluation: {
        save: ['month-record-template/save','id', 'begin_time', 'end_time', 'month', 'plan_id', 'topic_id', 'config'],
    },
    Print: {
        export: ['rpc/render-week-plan', 'url', 'year', 'week', 'plan_id'],
        // export: ['week-plan/render', 'year', 'week', 'plan_id'], // 弃用 需要 admin/1 school/1
    },
    Authority: {
        // 角色
        list: ['authority/role/list'],               // 角色列表
        save: ['authority/role/save', 'id', 'name'], // 新建角色/编辑角色名
        delete: ['authority/role/delete', 'id'],     // 删除角色

        // 权限
        authorityListAll: ['authority/list-all'], // 所有权限项
        roleAuthorityList: ['authority/list-by-role', 'role_id'], // 某角色已有权限项

        saveRoleAuthority: ['authority/role-authority/save', 'role_id', 'authority_id_strs'], // 更改 某角色 权限项
        deleteRoleAuthority: ['authority/role-authority/delete', 'id'],
    },
	Order: {
		createInvitationOrder: ['operation-order/create-invitation-order', 'school_id', 'price', 'charge', 'invitation_count'],
		update: ['operation-order/update', 'id', 'price', 'charge', 'invitation_count'],
		pay: ['operation-order/pay', 'sn', 'pay_type', 'user_id'],
		list: ['operation-order/list', 'page', 'page_size', 'school_id', 'type', 'user_id', 'sn', 'begin_time', 'end_time'],
		detail: ['operation-order/detail', 'id'],
	},
    Course: {
        list: ['course/list', 'page', 'page_size', 'media_type', 'group_id', 'status', 'parent_id', 'name'],
        detail: ['course/detail', 'id'],
        delete: ['course/delete', 'id'],
        updateStatus: ['course/update-status', 'id'], // 修改课程的上下架状态
        copy: ['course/copy', 'id'],
        copyByItem: ['course/copy-add-item', 'id', 'copy_id'],
        // studentList: ['course/list', '','page', 'page_size'],
        learningProgressList: ['account-course/list', 'course_id','page', 'page_size'], // 学习进度
        courseStat: ['account-course/stat', 'course_id','begin_time', 'end_time'], // 统计数据


        // 专栏 内容管理
        columnList: ['course/list-by-parent-id', 'parent_id'],
        addItem: ['course/add-item', 'id','child_ids'],
        removeItem: ['course/delete-item', 'id', 'child_id'],
        updateItemSeq: ['course/update-item-seq', 'parent_id','ids'],
    },
    Coupon: {
        list: ['coupon/list', 'type', 'use_type', 'page', 'page_size'],
        detail: ['coupon/detail', 'id'],
        delete: ['coupon/delete', 'id'],
        whiteSave: ['coupon-white-panel/create', 'coupon_id', 'phone'],
        whiteList: ['coupon-white-panel/list-by-coupon', 'coupon_id', 'page'],
        whiteDelete: ['coupon-white-panel/delete', 'coupon_id', 'phone'],
        useGoods: ['coupon-goods/list', 'coupon_id', 'page', 'page_size'],
        accountCoupon: ['account-coupon/list', 'coupon_id', 'page', 'page_size', 'status'],
    },
    Banner: {
        list: ['advertising/list', 'position', 'page', 'page_size'],
        save: ['advertising/save', 'id', 'image', 'position', 'redirect_type', 'title', 'content', 'target_id', 'target_type'],
        detail: ['advertising/detail', 'id'],
        delete: ['advertising/delete', 'id'],
    },
    ExChangeCode: {
        list: ['course-cdkey-activity/list', 'status', 'begin_time', 'end_time', 'page', 'page_size'],
        save: ['course-cdkey-activity/save', 'id', 'name', 'count', 'begin_time', 'end_time', 'user_per_count', 'target_id'],
        detail: ['course-cdkey-activity/detail', 'id'],
        delete: ['course-cdkey-activity/delete', 'id'],
        listByActivity: ['course-cdkey/list', 'activity_id', 'status', 'name','page', 'page_size']
    },
    UploadFile: {
        uploadFile: ['file/get-upload-signature-for-client', 'type', 'file_name']
    },
    OperSet: { // 运营设置
        channelList: ['promotion-channel/list', 'page', 'page_size'],
        channelSave: ['promotion-channel/save', 'id', 'name'],
        channelDelete: ['promotion-channel/delete', 'id'],

        courseList: ['experience-course/list', 'page', 'page_size'],
        courseSave: ['experience-course/save', 'id', 'name'],
        courseDelete: ['experience-course/delete', 'id'],
    },
    Device: {
        list: ['camera/list', 'school_id', 'uid', 'name', 'type', 'page', 'page_size'],
        detail: ['camera/detail', 'id'],
        delete: ['camera/delete', 'id'],
        save: ['camera/save', 'id', 'name', 'uid', 'school_id', 'channel', 'serial_number', 'type'],
        classBySchool: ['school-class/paging', 'school_id', 'page', 'page_size'],
        bindClass: ['camera/bind-class', 'id', 'class_id'],
        bindSchool: ['camera/bind-school', 'id', 'school_id'],
        openTimeConfig: ['camera/update-config', 'id', 'config'],
        openTimeConfigMultiple: ['camera/batch-update-config', 'ids', 'config'],

        schoolOpenAll: ['camera/update-school-open-flag', 'id'],
        parentOpen: ['camera/update-parent-open-flag', 'id'],
        teacherOpen: ['camera/update-teacher-open-flag', 'id'],
        teacherVoiceOpen: ['camera/update-teacher-voice-open-flag', 'id'],
        parentVoiceOpen: ['camera/update-parent-voice-open-flag', 'id'],

        accessToken: ['camera/access-token'],
        channelList: ['camera/channel-list', 'serial_number'],
		cloudStorageEnable: ['camera/set-cloud-storage-enable', 'id', 'enable'],
		cloudStorageInfo: ['camera/query-cloud-storage-info', 'id'],

		diagnose: ['camera/device-info', 'serial_number'],
    },
    OSS: {
        recoverFile: ['file/oss-restore', 'file_key']
    }
}
export default new Api(baseUrl, apiList);
